import { JSX } from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from './common/index';
import { Icon } from './Icon';

export type TileProps = {
  imageSrc: string;
  label: string;
  link: string;
  target?: string;
};
export default function Tile(props: TileProps): JSX.Element {
  const { imageSrc, label, link, target } = props;

  const icon = !imageSrc.startsWith('/') ? imageSrc : undefined;

  return (
    <div className="flex flex-col items-center space-y-4 p-4">
      <NavLink
        to={link}
        target={target}
        title={label}
        className="size-36 rounded-lg border-4 border-black bg-white bg-cover p-6 shadow-lg hover:bg-gray-200"
      >
        {icon ? <Icon className="text-[86px]" name={icon} /> : <img src={imageSrc} alt={label} />}
      </NavLink>
      <div>
        <Button className="uppercase" link={link} target={target}>
          {label}
        </Button>
      </div>
    </div>
  );
}
