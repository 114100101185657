import { Params } from 'react-router';

import { TaskType } from '../../../../backend/src/model/tasks/types.ts';
import { api } from '../../api';
import { getFilter } from '../../helper';

export const TASKS_DEFAULT_ORDER_BY = 'id:desc';
export const TASKS_PAGE_SIZE = 25;

export async function taskLoader({ params }: { params: Params }) {
  return { task: await api.fetchTask(Number(params.id)) };
}

export const tasksLoader =
  (type: TaskType) =>
  async ({ request }: { request: Request }) => {
    const query = new URL(request.url).searchParams;
    const page = Number(query.get('page')) || 1;
    const params = {
      orderBy: query.get('orderBy') || TASKS_DEFAULT_ORDER_BY,
      offset: (page - 1) * TASKS_PAGE_SIZE,
      limit: TASKS_PAGE_SIZE,
    };
    return { tasks: await api.findTasks(params, { ...getFilter(query), type }) };
  };
