import { useMemo, useState } from 'react';

import { NextCloudImage } from '../../../components/common/NextCloudImage';
import { getNewColumnSort } from '../../../helper';
import EntityTable, { EntityTableColumn } from '../../EntityTable';
import { Icon } from '../../Icon';
import { IdentifiableSerializedFileStat } from './Image';

export const IMAGES_DEFAULT_ORDER_BY = 'lastModified:desc';
export const IMAGES_PAGE_SIZE = 5;

type ImageChooserProps = {
  items: IdentifiableSerializedFileStat[];
  onSelect: (file: IdentifiableSerializedFileStat) => void;
};

export function ImageChooser(props: ImageChooserProps) {
  const { items, onSelect } = props;

  const columns: EntityTableColumn<IdentifiableSerializedFileStat>[] = [
    {
      label: 'Vorschau',
      prop: 'filename',
      sortable: false,
      renderer: (filename, row) => {
        if (row.type === 'directory') {
          return <Icon name="folder_open" className="text-6xl text-fuxs-orange-dark" />;
        }
        return <NextCloudImage file={String(filename)} alt={String(filename)} width="160" height="160" />;
      },
    },
    {
      label: 'Dateiname',
      prop: 'basename',
      sortable: true,
    },
    {
      label: 'Größe',
      prop: 'size',
      sortable: true,
      showSize: 'sm',
    },
    {
      label: 'Letzte Änderung',
      prop: 'lastModified',
      sortable: true,
      showSize: 'xs',
    },
  ];

  const getRowOnClickHandler = (selected: IdentifiableSerializedFileStat) => () => {
    onSelect(selected);
  };

  const [sortOrder, setSortOrder] = useState(IMAGES_DEFAULT_ORDER_BY);

  const getColumnSort = (column: EntityTableColumn<IdentifiableSerializedFileStat>) => {
    const { currentDir, newOrderBy } = getNewColumnSort(column, sortOrder, IMAGES_DEFAULT_ORDER_BY);
    return {
      sortOnClickHandler: () => setSortOrder(newOrderBy),
      orderDir: currentDir,
    };
  };

  const sortedItems = useMemo(() => {
    function getValue(stats: IdentifiableSerializedFileStat, prop: string) {
      const value = stats[prop as keyof IdentifiableSerializedFileStat];
      if (prop === 'lastModified') {
        return new Date(value).getTime();
      }
      return value;
    }

    return items.sort((a, b) => {
      if (a.type === 'directory' && b.type === 'file') {
        return -1;
      }
      if (a.type === 'file' && b.type === 'directory') {
        return 1;
      }

      const [property, dir] = sortOrder.split(':');
      const aValue = getValue(a, property);
      const bValue = getValue(b, property);
      if (aValue < bValue) {
        return dir === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return dir === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [items, sortOrder]);

  return (
    <div>
      {!items.length ? (
        <p>Keine Bilder gefunden</p>
      ) : (
        <>
          {/* REVISIT: Do we need a filter? If so, how can we implement it? */}
          <EntityTable
            columns={columns}
            rows={sortedItems}
            className="mb-8 w-full"
            getColumnSort={getColumnSort}
            getRowOnClickHandler={getRowOnClickHandler}
          />
          {/* REVISIT: Do we need a pager?
          <div className="flex justify-center">
            <Pagination page={page} pageSize={MATERIALS_PAGE_SIZE} total={total} />
          </div>
           */}
        </>
      )}
    </div>
  );
}
