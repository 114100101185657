import { Outlet, useLoaderData, useNavigate, useParams } from 'react-router';

import { useUser } from '@/UserProvider';

import { User } from '../../../backend/src/model/users/types';
import Message from '../components/common/Message';
import { TabNav, TabNavPropsItem } from '../components/TabNav';
import TopBar from '../components/TopBar';
import { StudentsSwitcher } from './components/StudentsSwitcher';

export function StudentsPage() {
  const user = useUser();
  const { studentId } = useParams();
  const students = (useLoaderData() as User[]) || undefined;

  const tabNav: TabNavPropsItem[] = [
    {
      label: 'Arbeitspläne',
      link: studentId ? `/students/plans/${studentId}` : '/students/plans',
    },
    {
      label: 'Tagesberichte',
      link: studentId ? `/students/reports/${studentId}` : '/students/reports',
    },
    {
      label: 'Kompetenzen',
      link: studentId ? `/students/competencies/${studentId}` : '/students/competencies',
    },
  ];

  const navigate = useNavigate();

  const selectStudent = (studentId: number) => {
    const parts = location.pathname.split('/');
    navigate([...parts.slice(0, 3), studentId, ...parts.slice(4)].join('/'));
  };

  return (
    <div>
      <TopBar user={user} />

      <div className="mx-auto flex h-full flex-col p-4">
        <Message />

        <div className="flex flex-col justify-between md:flex-row md:items-center">
          <h1 className="mb-4 text-3xl font-bold">Schülerinnen & Schüler</h1>
          {studentId && <StudentsSwitcher students={students} selection={{ studentId }} onChange={selectStudent} className="mb-8" />}
        </div>
        <TabNav className="mb-8 flex-col xs:flex-row" items={tabNav} />
        <Outlet />
      </div>
    </div>
  );
}
