import { Params } from 'react-router';

import { api } from '../../api';
import { getFilter } from '../../helper';

export const MATERIALS_DEFAULT_ORDER_BY = 'id:desc';
export const MATERIALS_PAGE_SIZE = 25;

export async function materialLoader({ params }: { params: Params }) {
  return await api.fetchMaterial(Number(params.id));
}

export async function materialsLoader({ request }: { request: Request }) {
  const query = new URL(request.url).searchParams;
  const page = Number(query.get('page')) || 1;
  const params = {
    orderBy: query.get('orderBy') || MATERIALS_DEFAULT_ORDER_BY,
    offset: (page - 1) * MATERIALS_PAGE_SIZE,
    limit: MATERIALS_PAGE_SIZE,
  };
  return await api.findMaterials(params, getFilter(query));
}
