import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { MessageProvider } from './MessageProvider';
import { router } from './routes';
import { StackProvider } from './StackProvider';
import { UserProvider } from './UserProvider';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <UserProvider>
      <StackProvider>
        <MessageProvider>
          <RouterProvider router={router} />
        </MessageProvider>
      </StackProvider>
    </UserProvider>
  </React.StrictMode>
);
