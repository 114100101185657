import { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

import Logo from './components/Logo';
import { TextLink } from './components/TextLink';

type ERROR_SPEC_ENTRY = {
  title?: string;
  image?: {
    src: string;
    width: number;
    height: number;
  };
  pageMessage?: string;
  apiMessage: string;
};

const ERROR_SPEC: Record<number, ERROR_SPEC_ENTRY> = {
  400: {
    apiMessage: 'Bad Request',
  },
  404: {
    title: 'Der Inhalt wurde leider nicht gefunden!',
    pageMessage: '',
    apiMessage: 'Resource not found',
  },
  401: {
    apiMessage: 'Unauthorized',
  },
  500: {
    title: 'Interner Fehler',
    pageMessage: 'Ein interner Fehler ist aufgetreten:',
    apiMessage: 'Internal error',
  },
};

// eslint-disable-next-line react-refresh/only-export-components
export enum ErrorType {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  INTERNAL = 500,
}

export function ErrorPage() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = useRouteError() as any;
  console.error(error);

  const navigate = useNavigate();
  useEffect(() => {
    if (error.message && error.message.match(/unauthorized/i)) {
      navigate('/'); // Redirect to home if not logged in
    }
  }, [error.message]);

  // FIXME: Convert to spec
  const type = ErrorType.NOT_FOUND;
  const spec = ERROR_SPEC[type];

  return (
    <div id="error-page">
      {/* <NavBar /> */}

      <div className="mx-auto px-4 py-8">
        <div className="mx-auto flex max-w-screen-md flex-col items-center justify-center">
          {spec.image ? (
            <img className="mb-6" src={spec.image.src} width={spec.image.width} height={spec.image.height} alt={spec.title} />
          ) : (
            <Logo className="mb-8 w-2/4" />
          )}
          <h1 className="text-4xl font-bold">
            {type} - {spec.title}
          </h1>
          <p className="my-4">{spec.pageMessage}</p>
          {error && <pre>{error.message}</pre>}
          <p className="my-4">
            Hier geht es zu der <TextLink onClick={() => history.back()}>zuletzt besuchten Seite</TextLink> zurück. Oder lieber nochmal auf
            die <TextLink href="/">Startseite</TextLink>?
          </p>
        </div>
      </div>
    </div>
  );
}
