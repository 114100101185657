import { ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = UseFormReturn<T> & {
  className?: string;
  children: ReactNode;
  onSubmit: (e?: React.BaseSyntheticEvent) => void | Promise<void>;
};

export function Form<T extends FieldValues>({ className, children, onSubmit, ...form }: Props<T>) {
  return (
    <FormProvider {...form}>
      <form className={className} onSubmit={onSubmit}>
        {children}
      </form>
    </FormProvider>
  );
}
