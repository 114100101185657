import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef } from 'react';

// NOTE: Code without too much thinking taken from https://www.developerway.com/posts/debouncing-in-react

export function useDebounce(callback: () => void | Promise<void>, delay = 1000) {
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, delay);
  }, [delay]);

  return debouncedCallback;
}
