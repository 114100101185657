import { ReactNode } from 'react';
import { DefaultValues, FieldValues, SubmitHandler } from 'react-hook-form';

import { useFormSubmission } from '../../../hooks/useFormSubmission';
import { Icon } from '../../Icon';
import { Button } from '../Button';
import { Form } from './Form';
import { Input } from './Input';
import { Label } from './Label';

type Props<T extends FieldValues> = {
  entity?: T;
  className?: string;
  children: ReactNode;
  onSubmit: SubmitHandler<T>;
  onCancel?: () => void;
  showId?: boolean;
};

export function EntityForm<T extends FieldValues>(props: Props<T>) {
  const { children, className, entity, onCancel, onSubmit, showId } = props;

  const form = useFormSubmission<T>({ onSubmit, defaultValues: entity as DefaultValues<T> });
  const hasErrors = Object.keys(form.formState.errors).length > 0;

  return (
    <Form className={className} {...form}>
      {hasErrors && <p className="text-error-red">Es sind Fehler aufgetreten.</p>}

      <div className="mb-8 flex flex-col gap-4">
        {entity?.id && showId && (
          <Label name="ID">
            <Input name="id" value={entity.id} disabled className="w-full outline-none" />
          </Label>
        )}

        {children}
      </div>

      <div className="flex flex-row">
        {onCancel && (
          <Button onClick={onCancel} className="hover:bg-fuxs-orange-light">
            <Icon className="mr-1" name="cancel" />
            Abbrechen
          </Button>
        )}

        <div className="grow"></div>

        <Button type="submit" className="hover:bg-fuxs-orange-light">
          {!entity?.id ? '➕ Erstellen' : '💾 Speichern'}
        </Button>
      </div>
    </Form>
  );
}
