import { useNavigate } from 'react-router';

import { useStack } from '../../StackProvider';

export default function LastStackItem() {
  const { stack, stackPop } = useStack();
  const navigate = useNavigate();

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const item = stackPop();
    if (!item) {
      return;
    }
    const { pathname, search, state } = item;
    const url = `${pathname}${search || ''}`;
    navigate(url, { state });
  };

  if (stack.length == 0) {
    return <></>;
  }

  const item = stack[stack.length - 1];
  const href = `${item.pathname}${item.search || ''}`;

  return (
    <li>
      <a onClick={onClick} href={href} className="flex flex-wrap gap-1">
        <span className="font-bold">{item.type === 'unsaved' ? 'Ungespeichert' : 'Zurück'}:</span>
        <span className="underline">{item.label}</span>
      </a>
    </li>
  );
}
