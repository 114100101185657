import { JSX } from 'react';

import { type User } from '../../../backend/src/model/users/types';
import Avatar from './Avatar';
import LastStackItem from './common/LastStackItem';
import { Icon } from './Icon';
import Navigation from './Navigation';

type NavigationProps = JSX.IntrinsicElements['nav'] & {
  user?: User;
};

export default function TopBar(props: NavigationProps) {
  const { user } = props;

  return (
    <nav className="min-h-[42px] border-b-2 border-black bg-white p-2">
      <ul className="flex items-center gap-2">
        <li>
          <Navigation user={user} />
        </li>

        {/* Bread Crumbs will be here */}
        <LastStackItem />

        <li className="flex-grow"></li>

        {/* Will be replaced with a dynamic login indicator soon */}
        {user && (
          <li className="flex items-center gap-1">
            <Avatar user={user} small />
            <span className="m-1">{user.displayName}</span>
            <a href="/logout" className="flex hover:text-fuxs-orange">
              <Icon name="logout" />
            </a>
          </li>
        )}
        {!user && (
          <li className="flex items-center gap-1">
            <a href="/login" className="flex hover:text-fuxs-orange">
              <Icon name="login" />
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
}
