import { useLoaderData, useNavigate, useParams } from 'react-router';

import { User } from '../../../backend/src/model/users/types';
import { StudentsSelector } from './components/StudentsSelector';

export function StudentsReportsPage() {
  const { studentId } = useParams();
  const { student } = useLoaderData() as { student: User };

  const navigate = useNavigate();

  const onSelectStudent = (student: User) => {
    navigate([location.pathname, student.id].join('/'));
  };

  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">Logbücher{studentId ? ` für ${student.displayName}` : ''}</h2>
      <div className="mb-4 flex justify-end">
        {/* <QueryTasksFilteForm subjects={subjects} groups={groups} className="grow" />
        <Button link={[location.pathname, 'new'].join('/')}>{texts.newItem}</Button> */}
      </div>
      {!studentId && <StudentsSelector onSelect={onSelectStudent} />}
    </div>
  );
}
