import { JSX } from 'react';

import Tile from './Tile';

export default function SchoolDesk(): JSX.Element {
  return (
    <div className="size-full bg-hero bg-cover bg-no-repeat">
      <div className="grid size-full grid-flow-row-dense grid-cols-2 items-center bg-desk bg-contain bg-scroll bg-center bg-no-repeat bg-blend-exclusion sm:grid-cols-3 sm:bg-contain md:grid-cols-3 md:bg-auto lg:grid-cols-3 lg:bg-auto xl:grid-cols-3 xl:bg-auto">
        <div className="flex-grow">
          <Tile label="Arbeitspläne" imageSrc="/tiles/sheet.svg" link="/students/plans" />
        </div>
        <div className="flex-grow">
          <Tile label="Tagesberichte" imageSrc="/tiles/book.svg" link="/students/reports" />
        </div>
        <div className="flex-grow">
          <Tile label="Kompetenzen" imageSrc="checklist" link="/students/competencies" />
        </div>
        <div className="flex-grow">
          <Tile label="Zuständige" imageSrc="/tiles/people.svg" link="" />
        </div>
        <div className="flex-grow">
          <Tile label="Website" imageSrc="/logo.png" link="https://www.fuxs.schule/" target="_blank" />
        </div>
        <div className="flex-grow">
          <Tile label="FuXs-Pfade" imageSrc="/tiles/fox.svg" link="/paths" />
        </div>
        <div className="flex-grow">
          <Tile label="Sammlung / Katalog" imageSrc="book_2" link="/entities" />
        </div>
        <div className="flex-grow">
          <Tile label="Kern-Curriculum" imageSrc="apps" link="/curriculum" />
        </div>
      </div>
    </div>
  );
}
