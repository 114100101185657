import { ComponentProps } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import { Button } from '../Button';

type CaptureFormButtonProps = Omit<ComponentProps<typeof Button>, 'onClick'> & {
  onClick?: (form: FieldValues) => void;
};

export default function CaptureFormButton(props: CaptureFormButtonProps) {
  const { children, onClick, ...rest } = props;

  const { getValues } = useFormContext();

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (onClick) {
      const values = getValues();
      onClick(values);
    }
  };

  return (
    <Button onClick={onClickHandler} {...rest}>
      {children}
    </Button>
  );
}
