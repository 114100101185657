import type { User } from '../../../../backend/src/model/users/types';
import Avatar from '../../components/Avatar';
import { Icon } from '../../components/Icon';

type StudentTileProps = {
  student: User;
  onClick?: () => void;
};

export function StudentTile(props: StudentTileProps) {
  const { student, onClick } = props;

  return (
    <div
      className={`flex items-center gap-2 border border-black p-4 transition-all hover:bg-fuxs-orange-light${onClick ? ' cursor-pointer' : ''}`}
      onClick={onClick}
    >
      <Avatar user={student} />
      <div className="ml-2">{student.displayName}</div>
      <div className="flex-grow" />
      <Icon name="chevron_right" />
    </div>
  );
}
