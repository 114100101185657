import { useEffect, useMemo } from 'react';
import { useLoaderData, useNavigate, useParams, useRevalidator } from 'react-router';

import { UserPlanTask } from '../../../../backend/src/model/plans/types';
import { TaskType } from '../../../../backend/src/model/tasks/types';
import { api } from '../../api';
import Card, { CardColumns, CardRow, CardWithColumns } from '../../components/common/Card';
import TaskCard from '../../components/TaskCard';
import { SubjectsAndGroups } from '../../curriculum/loaders';
import { dateTimeRenderer } from '../../helper';
import { useUpdateMessageState } from '../../MessageProvider';
import { PlanWithTaksAndUser } from '../loaders';
import { getPlanStatusActionRenderer, planStatusRenderer } from './utils';

export function ViewPlanPage() {
  const { plan, subjects, groups } = useLoaderData() as SubjectsAndGroups & { plan: PlanWithTaksAndUser };
  const updateMessageState = useUpdateMessageState();

  const { studentId } = useParams();
  if (!studentId) {
    throw new Error('No studentId provided');
  }

  const navigate = useNavigate();

  // NOTE: StudenSelector was changed
  useEffect(() => {
    if (studentId !== plan.userId.toString()) {
      console.warn(`Cancelling editing plan of student ${plan.userId} and switching to ${studentId}`);
      navigate(`../${studentId}`);
    }
  }, [studentId, plan.userId, navigate]);

  const orderedTasks = useMemo(
    () => plan.tasks.filter((t: UserPlanTask) => t.type === TaskType.Normal).sort((a: UserPlanTask, b: UserPlanTask) => a.order - b.order),
    [plan.tasks]
  );

  const orderedProjects = useMemo(
    () => plan.tasks.filter((t: UserPlanTask) => t.type === TaskType.Project).sort((a: UserPlanTask, b: UserPlanTask) => a.order - b.order),
    [plan.tasks]
  );

  const revalidator = useRevalidator();
  const toggleTask = (key: 'finished' | 'confirmed') => async (taskId: number) => {
    const task = plan.tasks.find((t: UserPlanTask) => t.id === taskId);
    await api.updatePlanTask(plan.id, taskId, { [key]: !task[key] });
    revalidator.revalidate();
  };

  const planStatusActionRenderer = getPlanStatusActionRenderer({
    editPlan: (id: number) => {
      navigate([location.pathname, 'edit'].join('/'));
    },
    activatePlan: async (id: number) => {
      await api.activatePlan(id);
      updateMessageState({
        text: 'Der Plan wurde erfolgreich aktiviert',
        level: 'success',
      });
      revalidator.revalidate();
    },
    deletePlan: async (id: number) => {
      await api.deletePlan(id);
      updateMessageState({
        text: 'Der Plan wurde erfolgreich gelöscht',
        level: 'success',
      });
      navigate(['..', studentId].join('/'));
    },
  });

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-2xl font-bold">Arbeitsplan für von {plan.user.displayName}</h1>
        {planStatusActionRenderer(plan.id, plan)}
      </div>

      <Card>
        <CardRow className="flex-col sm:flex-row">
          <CardColumns
            columns={[
              { title: 'Ersteller', children: <p>{plan.createdBy}</p> },
              { title: 'Erstelldatum', children: <p>{dateTimeRenderer(plan.createdAt)}</p> },
              { title: 'Status', children: <p>{planStatusRenderer(plan.status)}</p> },
            ]}
          />
        </CardRow>
      </Card>

      <CardWithColumns columns={[{ title: 'Ziel', children: <p>{plan.target}</p> }]} />

      {orderedTasks.length > 0 && (
        <>
          <h2 className="mb-4 text-xl font-bold">Aufgaben</h2>
          {orderedTasks.map((task: UserPlanTask, i: number) => (
            <TaskCard
              key={task.id}
              position={i + 1}
              task={task}
              student={plan.user}
              subjects={subjects}
              groups={groups}
              toggleTaskFinished={toggleTask('finished')}
              toggleTaskConfirmed={toggleTask('confirmed')}
            />
          ))}
        </>
      )}

      {orderedProjects.length > 0 && (
        <>
          <h2 className="mb-4 mt-8 text-xl font-bold">Projekt(e)</h2>
          {orderedProjects.map((task: UserPlanTask, i: number) => (
            <TaskCard
              key={task.id}
              position={i + 1}
              task={task}
              student={plan.user}
              subjects={subjects}
              groups={groups}
              toggleTaskFinished={toggleTask('finished')}
              toggleTaskConfirmed={toggleTask('confirmed')}
            />
          ))}
        </>
      )}
    </div>
  );
}
