import { ComponentProps } from 'react';
import { FieldPath, FieldValues, PathValue, RegisterOptions, useFormContext } from 'react-hook-form';

import { Switch } from '@/components/ui/switch';

type SwitchFieldProps<T extends FieldValues> = ComponentProps<typeof Switch> & {
  name: FieldPath<T>;
  fieldOptions?: RegisterOptions<T>;
};

export function SwitchField<T extends FieldValues>(props: SwitchFieldProps<T>) {
  const { children, className, name, fieldOptions, ...rest } = props;

  const { register, getValues, setValue } = useFormContext<T>();

  const value = getValues(name) || 'false';

  const onChange = (checked: boolean) => {
    const newValue = checked ? 'true' : 'false';
    setValue(name, newValue as PathValue<T, FieldPath<T>>, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return (
    <div className="flex items-center gap-2">
      <Switch className={className} onCheckedChange={onChange} value={value} {...register(name, { ...fieldOptions })} {...rest} />
      {children && (
        <label className="text-xs leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" htmlFor={name}>
          {children}
        </label>
      )}
    </div>
  );
}
