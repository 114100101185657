import { useEffect, useRef } from 'react';
import { is } from 'type-assurance';

export const useOnOutsideClick = (handleOutsideClick: () => void) => {
  const innerBorderRef = useRef<HTMLDivElement>(null);

  const onClick = (event: MouseEvent) => {
    const target = event.target;
    if (target && is(target, Node)) {
      if (innerBorderRef.current && !innerBorderRef.current.contains(target)) {
        handleOutsideClick();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', onClick, true);
    return () => {
      document.removeEventListener('click', onClick, true);
    };
  });

  return { innerBorderRef };
};
