import { Params } from 'react-router';

export type LoaderContext = { request: Request; params: Params };

export type Loader<T> = (context: LoaderContext) => Promise<T>;

type PropertyLoader<T extends { [key: string]: unknown }> = Loader<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CombinedPropertyLoadersResult<Loaders extends PropertyLoader<any>[]> = UnionToIntersection<
  {
    [K in keyof Loaders]: Loaders[K] extends PropertyLoader<infer R> ? R : never;
  }[number]
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function combinePropertyLoaders<Loaders extends PropertyLoader<any>[]>(
  ...loaders: Loaders
): (context: LoaderContext) => Promise<CombinedPropertyLoadersResult<Loaders>> {
  return async (context: LoaderContext) => {
    const results = await Promise.all(loaders.map((loader) => loader(context)));
    return results.reduce((acc, result) => ({ ...acc, ...result }), {} as CombinedPropertyLoadersResult<Loaders>);
  };
}

export function extractFindParamsFromRequest(request: Request, defaultOrderBy: string = 'id:desc', pageSize = 25) {
  const query = new URL(request.url).searchParams;
  const page = Number(query.get('page')) || 1;
  const findParams = {
    orderBy: query.get('orderBy') || defaultOrderBy,
    offset: (page - 1) * pageSize,
    limit: pageSize,
  };
  return { findParams, query };
}
