import { Generated, Insertable, Selectable, Updateable } from 'kysely';

export enum MaterialType {
  SelfLearn = 'selflearn',
  Book = 'book',
  Document = 'document',
  Exercise = 'exercise',
  App = 'app',
  Audio = 'audio',
  Video = 'video',
  Website = 'website',
  Other = 'other',
}

export type MaterialsFilter = {
  search?: string;
};

export interface MaterialTable {
  id: Generated<number>;
  type: MaterialType;
  label: string;
  description: string;
  location: string;
  url: string;
  image: string;
  createdBy: number;
  createdAt?: Date;
}
export type Material = Selectable<MaterialTable>;
export type NewMaterial = Insertable<MaterialTable>;
export type MaterialUpdate = Updateable<MaterialTable>;
