import { JSX, useEffect, useState } from 'react';
import { FieldPath, FieldValues, PathValue, RegisterOptions, useFormContext } from 'react-hook-form';

import { BreadCrumb } from '@/components/Icon';
import { cn } from '@/lib/utils';

import type { SerializedFileStat } from '../../../../../backend/src/model/files';
import { api } from '../../../api';
import { useDebounce } from '../../../hooks/useDebounce';
import { Button } from '../Button';
import { ModalPortal } from '../ModalPortal';
import { NextCloudImage } from '../NextCloudImage';
import { FilterForm } from './FilterForm';
import { ImageChooser } from './ImageChooser';
import { InputField } from './InputField';

export type IdentifiableSerializedFileStat = SerializedFileStat & { id: string };

export type InputProps<T extends FieldValues> = JSX.IntrinsicElements['input'] & {
  name: FieldPath<T>;
  fieldOptions?: RegisterOptions<T>;
  folder?: string;
  width?: string;
  height?: string;
};

type ImageFilter = {
  search?: string;
};

export function Image<T extends FieldValues>(props: InputProps<T>) {
  const { className, name, folder, width, height, fieldOptions } = props;

  const [showChooser, setShowChooser] = useState<boolean>(false);

  const [imagesToChoose, setImagesToChoose] = useState<IdentifiableSerializedFileStat[]>([]);

  const [filter, setFilter] = useState<ImageFilter>({});

  const [currentPath, setCurrentPath] = useState<string[]>(folder?.split('/').filter((p) => !!p) || []);

  const debouncedImageSearch = useDebounce(async () => {
    const path = '/' + currentPath.join('/');
    const files = await api.listFiles(path, filter.search);
    const mapped = files.map((f) => ({ ...f, id: f.filename }));

    if (currentPath.length > 1 && !filter.search) {
      mapped.unshift({
        id: '..',
        filename: '..',
        basename: '..',
        lastModified: '',
        size: 0,
        type: 'directory',
        etag: '',
      });
    }

    setImagesToChoose(mapped);
  }, 500);

  const showModal = async () => {
    document.body.style.overflow = 'hidden';
    setShowChooser(true);
  };

  const hideModal = () => {
    setShowChooser(false);
    document.body.style.overflow = 'unset';
  };

  const { setValue, getValues } = useFormContext();
  const image = getValues(name);

  const onSelect = (file: IdentifiableSerializedFileStat) => {
    if (file.type === 'directory') {
      if (file.filename === '..') {
        setCurrentPath(currentPath.slice(0, -1));
      } else {
        setCurrentPath([...currentPath, file.basename]);
      }
      return;
    }
    setValue(name, file.filename as PathValue<T, FieldPath<T>>, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    hideModal();
  };

  useEffect(() => {
    if (showChooser) {
      debouncedImageSearch();
    }
  }, [showChooser, currentPath, filter, debouncedImageSearch]);

  return (
    <>
      <div className={`flex gap-4 ${className}`}>
        <InputField name={name} className="inline-block grow" fieldOptions={fieldOptions} clearable readOnly disabled />
        <Button onClick={showModal}>Bild auswählen</Button>
      </div>
      {image && <NextCloudImage className="mt-4" file={image} width={width} height={height} />}

      {showChooser && (
        <ModalPortal>
          <div className="fixed left-0 top-0 size-full overflow-scroll p-4 backdrop-blur">
            <div className="mb-4 flex items-center gap-2">
              <FilterForm onChange={setFilter} filter={filter}>
                <InputField name="search" placeholder="Suche nach Dateiname ..." clearable />
              </FilterForm>

              <div className="grow" />

              <Button onClick={hideModal}>Schließen</Button>
            </div>

            {currentPath.length > 1 && (
              <ul className="mb-4 flex flex-wrap">
                {currentPath?.map((folder, i) => {
                  const isNotLast = i !== currentPath.length - 1;
                  return (
                    <li
                      key={folder}
                      className={cn('flex shadow-sm', isNotLast && 'hover:text-fuxs-orange-dark cursor-pointer')}
                      onClick={() => isNotLast && setCurrentPath(currentPath.slice(0, i + 1))}
                    >
                      <span className="mr-2">{folder}</span>

                      <span className="mr-3 w-2 text-black">
                        <BreadCrumb className="stroke-2" />
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}

            <h2 className="mb-4 text-2xl font-bold">Bild auswählen</h2>

            <ImageChooser items={imagesToChoose} onSelect={onSelect} />
          </div>
        </ModalPortal>
      )}
    </>
  );
}
