import { JSX } from 'react';

export type IconProps = Omit<JSX.IntrinsicElements['svg'], 'width' | 'height'>;

function icon(viewBox: string, ...paths: string[]) {
  // REVISIT: Can we not color with CSS fill and alwasys use currentColor?
  return function SvgIcon(props: IconProps & { color?: string }) {
    const { className, ...rest } = props;
    return (
      <svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" className={`inline-block fill-current ${className}`} {...rest}>
        {paths.map((p, i) => (
          <path key={i} d={p} fill={props.color} />
        ))}
      </svg>
    );
  };
}

function genericIcon(viewBox: string, children: JSX.Element) {
  return function SvgIcon(props: IconProps) {
    const { className, ...rest } = props;
    return (
      <svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" className={`inline-block fill-current ${className}`} {...rest}>
        {children}
      </svg>
    );
  };
}

export const Paw = icon(
  '0 0 200 205',
  'm-1.46,219.5c-.55-.57-1.18-1.09-1.78-1.55-55.91-42.92-111.81-85.85-167.72-128.77-1.3-.99-2.69-2.03-4.32-2.16-3.82-.31-3.44,3.77-3.46,6.32-.03,3.89-.05,7.79-.08,11.68-.05,7.79-.11,15.58-.16,23.36-.11,15.58-.22,31.15-.32,46.73-.1,15.11-.21,30.22-.31,45.33,0,.84,0,1.75.5,2.43.73,1.02,2.17,1.14,3.42,1.16,56.36.99,112.71,1.97,169.07,2.96,2.69.05,6.1-.4,6.86-2.99.49-1.66-.43-3.21-1.7-4.5Zm-76.46-19.29c-.33,1.11-1.79,1.3-2.95,1.28-24.19-.42-48.38-.85-72.58-1.27-.54,0-1.16-.06-1.47-.5-.21-.29-.22-.68-.21-1.04.04-6.49.09-12.97.13-19.46.05-6.69.09-13.37.14-20.06.02-3.34.05-6.69.07-10.03.01-1.67.02-3.34.03-5.01,0-1.09-.16-2.85,1.48-2.71.7.06,1.3.5,1.85.93,24,18.43,48,36.85,72,55.28.26.2.52.42.76.67.54.56.94,1.22.73,1.93Z',
  'm62.57,152.12c-2.48-3.01-4.59-7.78-8.77-8.05-5.14-.33-6.42,5.19-7.92,9.39-1.76,4.93-4.6,7.61-6.93,12.16-2.18,4.25-2.94,9.01,1.28,12.41,4.6,3.71,9.73.92,14.09-1.13,5.84-2.75,9.95-.63,15.85-2.1,5.54-1.38,8.79-7.04,4.53-12.53-3.39-4.36-8.56-5.84-12.11-10.15Z',
  'm16.91,131.4c-1.41-.7-3.35-.09-4.32,1.34-7.23,10.66-4.88,25.97,8.73,28.05,7.24,1.11,15.56-3.85,10.02-16.24-2.4-5.36-3.86-7.93-14.43-13.16Z',
  'm86.09,119.78c-1.4-1.01-3.43-.92-4.52.22-8.14,8.53-8.64,11.44-9.06,17.3-.97,13.54,8.55,15.35,14.97,11.83,12.07-6.62,9.04-21.81-1.4-29.35Z',
  'm30.99,129.78c7.53,3.07,17.12.74,12.59-15.05-1.96-6.83-3.33-8.6-13.37-18.95-1.27-1.31-4.25-2.26-5.43-.83-8.79,10.64-7.96,29.05,6.2,34.82Z',
  'm67.96,123.26c11.33-10.26,5.82-27.85-6.08-34.84-1.6-.94-4.07.96-4.82,2.64-5.89,13.16-6.57,15.29-6.08,22.38,1.15,16.39,10.95,15.29,16.98,9.83Z',
  'm86.73,116.04h0c1.2.69,2.73.06,3.1-1.27.07-.23.12-.47.17-.7.24-1.06.27-2.11.23-3.08-.05-.97-.25-1.88-.48-2.69-.26-.81-.56-1.53-.91-2.15-.69-1.24-1.46-2.08-2.03-2.58-.27-.26-.53-.42-.68-.55-.16-.11-.24-.18-.24-.18,0,0,.03.1.09.29.06.19.17.45.26.8.21.69.43,1.69.53,2.88.18,1.9-.07,4.32-.97,6.59-.39.99,0,2.11.92,2.64Z',
  'm59.38,84.49c1.46.22,2.53-.95,2.38-2.17-.03-.22-.06-.43-.09-.65-.15-.96-.42-1.85-.7-2.67-.28-.82-.65-1.57-1-2.26-.38-.69-.76-1.32-1.16-1.89-.79-1.13-1.54-2.01-2.1-2.58-.26-.29-.51-.5-.66-.64-.15-.14-.23-.22-.23-.22,0,0,.03.11.08.31.05.2.15.48.24.85.2.72.44,1.74.67,2.92.37,1.89.62,4.23.59,6.7-.02,1.08.87,2.12,1.98,2.29Z',
  'm6.75,129.11c.13.2.26.4.4.6.81,1.12,2.46,1.19,3.35.13.69-.81.67-2-.04-2.79-1.63-1.83-2.69-4.01-3.16-5.86-.31-1.15-.45-2.17-.48-2.89-.04-.36-.02-.64-.03-.84,0-.2,0-.3,0-.3,0,0-.06.09-.17.25-.1.17-.29.41-.45.75-.37.67-.8,1.71-1.03,3.12-.12.7-.16,1.48-.12,2.34.06.84.18,1.76.47,2.69.29.93.68,1.9,1.27,2.81Z',
  'm24.28,90.68c.99-.55,1.47-1.82,1.08-2.83-.87-2.31-1.44-4.59-1.73-6.5-.19-1.19-.31-2.23-.37-2.98-.05-.38-.04-.67-.07-.88-.02-.21-.03-.32-.03-.32,0,0-.05.1-.14.28-.09.19-.25.46-.4.83-.33.73-.74,1.81-1.09,3.14-.18.67-.32,1.39-.44,2.17-.09.77-.18,1.6-.17,2.47.02.87.06,1.79.26,2.75.04.21.08.42.13.64.27,1.2,1.68,1.94,2.98,1.23Z',
  'm152.01,99.03c-1.3-3.67-1.66-8.88-5.49-10.57-4.72-2.07-7.81,2.69-10.65,6.11-3.34,4.03-6.92,5.57-10.67,9.05-3.5,3.25-5.84,7.46-3.04,12.1,3.06,5.06,8.83,4.2,13.62,3.76,6.43-.59,9.56,2.81,15.61,3.45,5.68.6,10.67-3.61,8.54-10.22-1.69-5.26-6.05-8.41-7.91-13.68Z',
  'm116.19,63.94c-1.09-1.14-3.12-1.23-4.52-.22-10.44,7.54-13.47,22.73-1.4,29.35,6.42,3.52,15.94,1.71,14.97-11.83-.42-5.86-.91-8.77-9.06-17.3Z',
  'm185.17,76.69c-.97-1.43-2.91-2.04-4.32-1.34-10.57,5.23-12.03,7.8-14.43,13.16-5.54,12.39,2.78,17.35,10.02,16.24,13.61-2.09,15.95-17.4,8.73-28.05Z',
  'm129.97,67.23c6.03,5.46,15.83,6.55,16.98-9.83.5-7.09-.18-9.21-6.08-22.38-.75-1.67-3.22-3.57-4.82-2.64-11.9,7-17.42,24.58-6.08,34.84Z',
  'm166.94,73.75c14.16-5.77,14.99-24.18,6.2-34.82-1.18-1.43-4.16-.49-5.43.83-10.04,10.35-11.41,12.12-13.37,18.95-4.53,15.79,5.06,18.11,12.59,15.05Z',
  'm187.05,73.39h0c.89,1.06,2.54.99,3.35-.13.14-.2.28-.4.4-.6.59-.91.98-1.88,1.27-2.81.29-.93.41-1.85.47-2.69.03-.85,0-1.63-.12-2.34-.23-1.4-.66-2.45-1.03-3.12-.17-.34-.35-.58-.45-.75-.11-.16-.17-.25-.17-.25,0,0,0,.1,0,.3-.01.2,0,.48-.03.84-.03.72-.17,1.74-.48,2.89-.48,1.85-1.54,4.04-3.16,5.86-.71.79-.73,1.98-.04,2.79Z',
  'm172.14,34.39c1.3.71,2.7-.02,2.98-1.23.05-.22.09-.43.13-.64.19-.96.24-1.88.26-2.75.01-.87-.08-1.7-.17-2.47-.12-.78-.26-1.5-.44-2.17-.35-1.33-.76-2.42-1.09-3.14-.15-.37-.31-.64-.4-.83-.09-.18-.14-.28-.14-.28,0,0-.01.11-.03.32-.02.21-.02.5-.07.88-.05.75-.18,1.79-.37,2.98-.3,1.91-.86,4.19-1.74,6.5-.39,1.01.09,2.29,1.08,2.83Z',
  'm107.43,58.32c.05.23.11.47.17.7.38,1.33,1.9,1.96,3.1,1.27.92-.53,1.31-1.65.92-2.64-.9-2.27-1.15-4.69-.97-6.59.1-1.19.32-2.19.53-2.88.09-.35.2-.61.26-.8.06-.19.09-.29.09-.29,0,0-.08.06-.24.18-.15.12-.41.28-.68.55-.58.5-1.34,1.34-2.03,2.58-.35.62-.65,1.34-.91,2.15-.23.81-.43,1.72-.48,2.69-.04.97-.01,2.02.23,3.08Z',
  'm137.04,28.2c1.12-.17,2.01-1.21,1.98-2.29-.03-2.47.22-4.81.59-6.7.23-1.18.47-2.2.67-2.92.09-.37.19-.65.24-.85.05-.2.08-.31.08-.31,0,0-.08.08-.23.22-.15.15-.39.35-.66.64-.56.57-1.31,1.45-2.1,2.58-.4.56-.78,1.2-1.16,1.89-.35.7-.72,1.44-1,2.26-.28.82-.55,1.7-.7,2.67-.03.21-.07.43-.09.65-.16,1.22.92,2.39,2.38,2.17Z'
);

export const SwapVert = icon(
  '0 -960 960 960',
  'M320-440v-287L217-624l-57-56 200-200 200 200-57 56-103-103v287h-80ZM600-80 400-280l57-56 103 103v-287h80v287l103-103 57 56L600-80Z'
);

export const BreadCrumb = icon('0 0 14 44', 'm1.3 0-1.3 0.75 12.27 21.25-12.27 21.25 1.3 0.75 12.7-22z');

export const Circle = genericIcon('0 0 24 24', <circle cx="12" cy="12" r="10" />);

export const Star = genericIcon(
  '0 0 24 24',
  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
);

type MaterialIconProps = JSX.IntrinsicElements['span'] & {
  name: string;
};
export function Icon({ name, className, ...rest }: MaterialIconProps) {
  return (
    <span className={`material-symbols-sharp ${className ?? ''}`} {...rest}>
      {name}
    </span>
  );
}
