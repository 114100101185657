import { useEffect, useState } from 'react';

import type { PlanDetails } from '../../backend/src/model/plans/types';
import { isStudent, isTeacher } from '../../backend/src/model/users/helper';
import { api } from './api';
import StudentDesk from './components/StudenttDesk';
import TeacherDesk from './components/TeacherDesk';
import TopBar from './components/TopBar';
import Welcome from './components/Welcome';
import { useUser } from './UserProvider';

export function Home() {
  const user = useUser();

  const [activePlan, setActivePlan] = useState<PlanDetails | null>(null);
  useEffect(() => {
    if (user && isStudent(user)) {
      api.fetchMyActivePlan().then(setActivePlan);
    }
  }, [user]);

  return (
    <div>
      <TopBar user={user} />
      <div>
        {!user && <Welcome />}
        {user && isStudent(user) && <StudentDesk user={user} plan={activePlan} />}
        {user && isTeacher(user) && <TeacherDesk />}
        {user && !isStudent(user) && !isTeacher(user) && <Welcome userName={user.displayName} />}
      </div>
    </div>
  );
}
