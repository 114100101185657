import { useLoaderData, useLocation, useNavigate } from 'react-router';

import type { Subject } from '../../../../backend/src/model/subjects';
import { ServiceResult } from '../../../../backend/src/service';
import { Button } from '../../components/common';
import EntityTable, { EntityTableColumn } from '../../components/EntityTable';
import { QueryPagination } from '../../components/QueryPagination';
import { getNewSortQueryString, thumbnailRenderer } from '../../helper';
import { useQuery } from '../../hooks/useQuery';
import { SUBJECTS_DEFAULT_ORDER_BY, SUBJECTS_PAGE_SIZE } from './loaders';

export function SubjectsListPage() {
  const { result: items, total } = useLoaderData() as ServiceResult<Subject[]>;

  const location = useLocation();
  const query = useQuery();

  const page = Number(query.get('page')) || 1;

  const columns: EntityTableColumn<Subject>[] = [
    {
      label: 'ID',
      prop: 'id',
      sortable: true,
    },
    {
      label: 'Short',
      prop: 'shortCode',
      sortable: true,
    },
    {
      label: 'Name',
      prop: 'label',
      sortable: true,
    },
    {
      label: 'Bild',
      prop: 'image',
      renderer: thumbnailRenderer,
    },
  ];

  const navigate = useNavigate();
  const getRowOnClickHandler = (clicked: Subject) => () => {
    navigate([location.pathname, clicked.id].join('/'));
  };

  const getColumnSort = (column: EntityTableColumn<Subject>) => {
    const { currentDir, queryString } = getNewSortQueryString(column, query.toString(), SUBJECTS_DEFAULT_ORDER_BY);
    return {
      sortOnClickHandler: () => navigate(`${location.pathname}?${queryString}`),
      orderDir: currentDir,
    };
  };

  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">Fächer</h2>
      <div className="mb-4 flex justify-end">
        <Button link={[location.pathname, 'new'].join('/')}>Neues Fach</Button>
      </div>
      {!items.length ? (
        <p>Noch keine Fächer gefunden</p>
      ) : (
        <>
          <EntityTable
            columns={columns}
            rows={items}
            className="mb-8 w-full"
            getColumnSort={getColumnSort}
            getRowOnClickHandler={getRowOnClickHandler}
          />
          <div className="flex justify-center">
            <QueryPagination page={page} pageSize={SUBJECTS_PAGE_SIZE} total={total} />
          </div>
        </>
      )}
    </div>
  );
}
