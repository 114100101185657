import { USER_TYPE, User } from "./types.ts";

export const STUDENT_GROUP = "Kreis SchülerInnen";
export function isStudent(user?: User) {
  return user && user.groups.includes(STUDENT_GROUP) && !isTeacher(user);
}

export const TEACHER_GROUP = "Kreis PädagogInnen";
export function isTeacher(user?: User) {
  return user && user.groups.includes(TEACHER_GROUP);
}

export function getUserType(user: User): USER_TYPE {
  if (isStudent(user)) {
    return USER_TYPE.STUDENT;
  }
  if (isTeacher(user)) {
    return USER_TYPE.TEACHER;
  }

  return USER_TYPE.UNKNOWN;
}
