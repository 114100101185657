import { ComponentProps } from 'react';
import { FieldPath, FieldValues, PathValue, RegisterOptions, useFormContext } from 'react-hook-form';

import { Icon } from '@/components/Icon';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

type InputFieldProps<T extends FieldValues> = ComponentProps<typeof Input> & {
  name: FieldPath<T>;
  fieldOptions?: RegisterOptions<T>;
  clearable?: boolean;
};

export function InputField<T extends FieldValues>(props: InputFieldProps<T>) {
  const { className, clearable, name, fieldOptions, ...rest } = props;

  const { register, getValues, setValue } = useFormContext<T>();

  const onClear = () => {
    setValue(
      name,
      // REVISIT: Why is this cast necessary? Can we avoid it?
      '' as PathValue<T, FieldPath<T>>,
      { shouldValidate: true, shouldDirty: true, shouldTouch: true }
    );
  };

  const value = getValues(name) || '';

  return (
    <span className={cn('relative', className)}>
      <Input className={className} {...register(name, { ...fieldOptions })} {...rest} />
      {clearable && value && (
        <div className="absolute inset-y-0 right-0 flex items-center px-2">
          <Icon name="close" className="cursor-pointer text-xl text-gray-light" onClick={() => onClear()} />
        </div>
      )}
    </span>
  );
}
