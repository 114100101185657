import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { Material } from '@backend/model/materials/types';
import type { Plan } from '@backend/model/plans/types';
import type { Subject } from '@backend/model/subjects';
import { type Task, type TasksFilter, TaskType } from '@backend/model/tasks/types';
import { ServiceResult } from '@backend/service';
import { useState } from 'react';
import { DeepPartial, SubmitHandler } from 'react-hook-form';

import { api } from '@/api';
import { Input } from '@/components/common';
import CaptureFormButton from '@/components/common/form/CaptureFormButton';
import { Chooser, ItemQuery } from '@/components/common/form/Chooser';
import { EntityForm } from '@/components/common/form/EntityForm';
import { Label } from '@/components/common/form/Label';
import { TextareaField } from '@/components/common/form/TextareaField';
import EntityTable from '@/components/EntityTable';

import TasksFilterForm from '../../../entities/tasks/components/TasksFilterForm';
import { TASKS_DEFAULT_ORDER_BY, TASKS_PAGE_SIZE } from '../../../entities/tasks/loaders';
import { getTasksTableColumns } from '../../../entities/tasks/utils';

export type PlanFormProps = {
  studentId: number;
  plan?: Plan;
  subjects: Subject[];
  groups: CompetencyGroup[];
  onSubmit: SubmitHandler<Material>;
  onCancel?: () => void;
  onNewtTask?: (type: TaskType, plan: Plan) => void;
};

export default function TaskForm(props: PlanFormProps) {
  const { plan, studentId, subjects, groups, onSubmit, onCancel, onNewtTask } = props;

  const [tasksFilter, setTasksFilter] = useState<TasksFilter>({ type: TaskType.Normal });

  const onTasksFilterChange = (type: TaskType, updateFilterFn: (filter: TasksFilter) => void) => (value: DeepPartial<TasksFilter>) => {
    updateFilterFn({ ...value, type });
  };

  const tasksQueryFn = (query: ItemQuery, filter?: TasksFilter): Promise<ServiceResult<Task[]>> => {
    const params = {
      orderBy: query.orderBy,
      offset: (query.page - 1) * TASKS_PAGE_SIZE,
      limit: TASKS_PAGE_SIZE,
    };
    return api.findTasks(params, filter);
  };

  const [projectsFilter, setProjectsFilter] = useState<TasksFilter>({ type: TaskType.Project });

  const taskTableColumns = getTasksTableColumns(TaskType.Normal);
  const projectTableColumns = getTasksTableColumns(TaskType.Project);

  const onNewtTaskClick = (type: TaskType) => (plan: Plan) => {
    onNewtTask?.(type, plan);
  };

  return (
    <EntityForm entity={plan} onCancel={onCancel} onSubmit={onSubmit} showId>
      <Input name="userId" type="hidden" value={studentId} />

      <Label name="Ziel">
        <TextareaField name="target" required />
      </Label>

      <Label name="Aufgaben">
        <Chooser
          name="taskIds"
          transientProp="tasks"
          title="Aufgaben auswählen"
          itemsRenderer={(items) => <EntityTable className="w-full" columns={taskTableColumns} rows={items} />}
          filter={tasksFilter}
          filterForm={
            <TasksFilterForm
              className="flex-col md:flex-row"
              subjects={subjects}
              groups={groups}
              filter={tasksFilter}
              onChange={onTasksFilterChange(TaskType.Normal, setTasksFilter)}
            />
          }
          defaultOrderBy={TASKS_DEFAULT_ORDER_BY}
          itemQueryFn={tasksQueryFn}
          chooserColumns={taskTableColumns}
          choosenProp={'id'}
          onShow={() => setTasksFilter({ type: TaskType.Normal })}
          chooseMax={10}
          newButton={<CaptureFormButton onClick={onNewtTaskClick(TaskType.Normal)}>{'Neue Aufgabe'}</CaptureFormButton>}
        />
      </Label>

      <Label name="Projekte">
        <Chooser
          name="projectIds"
          transientProp="projects"
          title="Projekte auswählen"
          itemsRenderer={(items) => <EntityTable className="w-full" columns={projectTableColumns} rows={items} />}
          filter={projectsFilter}
          filterForm={
            <TasksFilterForm
              className="flex-col md:flex-row"
              subjects={subjects}
              groups={groups}
              filter={projectsFilter}
              onChange={onTasksFilterChange(TaskType.Project, setProjectsFilter)}
            />
          }
          defaultOrderBy={TASKS_DEFAULT_ORDER_BY}
          itemQueryFn={tasksQueryFn}
          chooserColumns={projectTableColumns}
          choosenProp={'id'}
          onShow={() => setProjectsFilter({ type: TaskType.Project })}
          chooseMax={3}
          newButton={<CaptureFormButton onClick={onNewtTaskClick(TaskType.Project)}>{'Neues Projekt'}</CaptureFormButton>}
        />
      </Label>
    </EntityForm>
  );
}
