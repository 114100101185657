import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import type { ServiceResult } from '@backend/service';
import { useLoaderData, useLocation, useNavigate } from 'react-router';

import { Button } from '@/components/common';
import EntityTable, { EntityTableColumn } from '@/components/EntityTable';
import { QueryPagination } from '@/components/QueryPagination';
import { getNewSortQueryString } from '@/helper';
import { useQuery } from '@/hooks/useQuery';

import GroupsFilteForm from './components/GroupsFilterForm';

export const GROUPS_DEFAULT_ORDER_BY = 'order:asc';
export const GROUPS_PAGE_SIZE = 25;

export function GroupsListPage() {
  const {
    groups: { result: items, total },
    subjects,
  } = useLoaderData() as { groups: ServiceResult<CompetencyGroup[]>; subjects: Subject[] };

  const location = useLocation();
  const query = useQuery();

  const page = Number(query.get('page')) || 1;

  const columns: EntityTableColumn<CompetencyGroup>[] = [
    {
      label: 'ID',
      prop: 'id',
      sortable: true,
      showSize: 'xs',
    },
    {
      label: 'Fach',
      prop: 'subjectId',
      renderer: (v) => {
        const subject = subjects.find((s) => s.id === v);
        return subject ? subject.label : '';
      },
      sortable: true,
    },
    {
      label: 'Name',
      prop: 'label',
      sortable: true,
    },
    // {
    //   label: 'Reihenfolge',
    //   prop: 'order',
    //   sortable: true,
    // },
  ];

  const navigate = useNavigate();
  const getRowOnClickHandler = (clicked: CompetencyGroup) => () => {
    navigate([location.pathname, clicked.id].join('/'));
  };

  const getColumnSort = (column: EntityTableColumn<CompetencyGroup>) => {
    const { currentDir, queryString } = getNewSortQueryString(column, query.toString(), GROUPS_DEFAULT_ORDER_BY);
    return {
      sortOnClickHandler: () => navigate(`${location.pathname}?${queryString}`),
      orderDir: currentDir,
    };
  };

  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">Gruppen / Teilbereiche</h2>
      <div className="mb-4 flex flex-col-reverse sm:flex-row">
        <GroupsFilteForm className="grow flex-col md:flex-row" subjects={subjects} />
        <Button link={[location.pathname, 'new'].join('/')} className="mb-4 sm:mb-0 sm:ml-4">
          Neue Gruppe
        </Button>
      </div>
      {!items.length ? (
        <p>Keine Gruppen gefunden</p>
      ) : (
        <>
          <EntityTable
            columns={columns}
            rows={items}
            className="mb-8 w-full"
            getColumnSort={getColumnSort}
            getRowOnClickHandler={getRowOnClickHandler}
          />
          <div className="flex justify-center">
            <QueryPagination page={page} pageSize={GROUPS_PAGE_SIZE} total={total} />
          </div>
        </>
      )}
    </div>
  );
}
