import { ComponentProps } from 'react';
import { FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';

import { Textarea } from '@/components/ui/textarea';

type TextareaFieldProps<T extends FieldValues> = ComponentProps<typeof Textarea> & {
  name: FieldPath<T>;
  fieldOptions?: RegisterOptions<T>;
};

export function TextareaField<T extends FieldValues>(props: TextareaFieldProps<T>) {
  const { className, name, fieldOptions, ...rest } = props;

  const {
    register,
    // formState: { errors },
  } = useFormContext<T>();

  // const error = get(errors, name);

  return <Textarea className={className} {...register(name, { ...fieldOptions })} {...rest} />;
}
