import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import { Task, TaskType } from '@backend/model/tasks/types';
import { useLoaderData, useNavigate } from 'react-router';
import { is } from 'type-assurance';

import { api } from '@/api';
import { useUpdateMessageState } from '@/MessageProvider';
import { useStack } from '@/StackProvider';

import TaskForm from './components/TaskForm';
import { TASK_TEXTS } from './utils';

export function NewTaskPage({ type }: { type: TaskType }) {
  const { subjects, groups } = useLoaderData() as {
    groups: CompetencyGroup[];
    subjects: Subject[];
  };

  const { stack, stackPop } = useStack();
  const navigate = useNavigate();

  const texts = TASK_TEXTS[type];

  function onCancel() {
    const { pathname, search, state } = stackPop() || {};
    if (pathname) {
      navigate(pathname + (search || ''), { state });
      return;
    }
    navigate('..');
  }

  const updateMessageState = useUpdateMessageState();
  const saveForm = async (data: Task) => {
    console.log('Saving', data);

    try {
      const task = await api.newTask(data);
      updateMessageState({
        text: texts.newSaveSuccess,
        level: 'success',
      });

      // Handle stacked plan if existing
      const stackedPlan = stack.length > 0 && stack[stack.length - 1];
      if (stackedPlan) {
        const { pathname, search, state } = stackedPlan;
        // console.log('state', state);
        if (
          is(state, {
            taskIds: [],
            tasks: [],
            projectIds: [],
            projects: [],
          })
        ) {
          stackPop(); // Pop from stack
          navigate(pathname + (search || ''), {
            state: {
              ...state,
              tasks: [...state.tasks, task],
            },
          });
          return;
        }
      }

      navigate('..');
    } catch (error) {
      console.error(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">{texts.newItem}</h1>
      <TaskForm type={type} subjects={subjects} groups={groups} onSubmit={saveForm} onCancel={onCancel} />
    </div>
  );
}
