import { SyntheticEvent } from 'react';
import { useLocation, useNavigate, useRevalidator } from 'react-router';

import { type Plan, PlanStatus } from '../../../../../backend/src/model/plans/types';
import type { SerializedDates } from '../../../../../backend/src/model/types';
import type { ServiceResult } from '../../../../../backend/src/service';
import { api } from '../../../api';
import EntityTable, { EntityTableColumn } from '../../../components/EntityTable';
import { QueryPagination } from '../../../components/QueryPagination';
import { getNewSortQueryString } from '../../../helper';
import { useQuery } from '../../../hooks/useQuery';
import { useUpdateMessageState } from '../../../MessageProvider';
import { PLANS_DEFAULT_ORDER_BY, PLANS_PAGE_SIZE } from '../../loaders';
import { getPlansTableColumns, getPlanStatusActionRenderer } from '../utils';

export function StudentPlans({ plans }: { plans: ServiceResult<SerializedDates<Plan>[]> }) {
  const updateMessageState = useUpdateMessageState();
  const revalidator = useRevalidator();
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const page = Number(query.get('page')) || 1;

  const getHandleViewPlan = (id: number) => (ev: SyntheticEvent | Event) => {
    ev.stopPropagation();
    navigate([location.pathname, id].join('/'));
  };

  const editPlan = (id: number) => {
    navigate([location.pathname, id, 'edit'].join('/'));
  };

  const activatePlan = async (id: number) => {
    await api.activatePlan(id);
    updateMessageState({
      text: 'Der Plan wurde erfolgreich aktiviert',
      level: 'success',
    });
    revalidator.revalidate();
  };

  const deletePlan = async (id: number) => {
    await api.deletePlan(id);
    updateMessageState({
      text: 'Der Plan wurde erfolgreich gelöscht',
      level: 'success',
    });
    revalidator.revalidate();
  };

  const planStatusActionRenderer = getPlanStatusActionRenderer({ editPlan, activatePlan, deletePlan });

  const plansTableColumns = getPlansTableColumns(getHandleViewPlan, planStatusActionRenderer);

  const getRowOnClickHandler = (plan: Plan) => () => {
    if (plan.status === PlanStatus.ARCHIVED) {
      navigate([location.pathname, plan.id].join('/'));
      return;
    }

    navigate([location.pathname, plan.id, 'edit'].join('/'));
  };

  const getColumnSort = (column: EntityTableColumn<Plan>) => {
    const { currentDir, queryString } = getNewSortQueryString(column, query.toString(), PLANS_DEFAULT_ORDER_BY);
    return {
      sortOnClickHandler: () => navigate(`${location.pathname}?${queryString}`),
      orderDir: currentDir,
    };
  };

  const { result: items, total } = plans;

  return (
    <>
      {!items.length ? (
        <p>Keine Pläne gefunden</p>
      ) : (
        <>
          <EntityTable
            columns={plansTableColumns}
            rows={items}
            className="mb-8 w-full"
            getColumnSort={getColumnSort}
            getRowOnClickHandler={getRowOnClickHandler}
          />
          <div className="flex justify-center">
            <QueryPagination page={page} pageSize={PLANS_PAGE_SIZE} total={total} />
          </div>
        </>
      )}
    </>
  );
}
