import type { User } from '@backend/model/users/types';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { useUser as useProviderUser } from './UserProvider';

type ContextType = { user: User | null };

export function App() {
  const user = useProviderUser();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to home if not logged in
    // REVISIT: Do we want a nice login page with a big orange button?
    if (!user && location.pathname !== '/') {
      navigate('/');
    }
  }, [user, location.pathname, navigate]);

  return <Outlet context={{ user } satisfies ContextType} />;
}
