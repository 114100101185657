import type { CompetenciesFilter, CompetencyGroup } from '@backend/model/competencies/types';
import type { Material, MaterialsFilter } from '@backend/model/materials/types';
import type { Subject } from '@backend/model/subjects';
import type { TaskType, TaskWithCompetencies } from '@backend/model/tasks/types';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { api } from '@/api';
import { Image, Input } from '@/components/common';
import { Chooser, ItemQuery } from '@/components/common/form/Chooser';
import { EntityForm } from '@/components/common/form/EntityForm';
import { InputField } from '@/components/common/form/InputField';
import { Label } from '@/components/common/form/Label';
import { SwitchField } from '@/components/common/form/SwitchField';
import { TextareaField } from '@/components/common/form/TextareaField';
import EntityTable from '@/components/EntityTable';

import { COMPETENCIES_DEFAULT_ORDER_BY, COMPETENCIES_PAGE_SIZE } from '../../../curriculum/competencies/CompentenciesListPage';
import CompetenciesFilterForm from '../../../curriculum/competencies/components/CompetenciesFilterForm';
import { getCompetencyColumns } from '../../../curriculum/competencies/utils';
import { MaterialComponent } from '../../materials/components/MaterialComponent';
import { MaterialsFilterForm } from '../../materials/components/MaterialsFilterForm';
import { MATERIALS_DEFAULT_ORDER_BY } from '../../materials/loaders';
import { materialTableColumns } from '../../materials/utils';
import { TASK_TEXTS } from '../utils';

export type TaskFormProps = {
  type: TaskType;
  task?: TaskWithCompetencies;
  subjects: Subject[];
  groups: CompetencyGroup[];
  onSubmit: SubmitHandler<Material>;
  onCancel?: () => void;
};

export default function TaskForm(props: TaskFormProps) {
  const { type, task, subjects, groups, onSubmit, onCancel } = props;

  const texts = TASK_TEXTS[type];

  const [materialsFilter, setMaterialsFilter] = useState<MaterialsFilter>({});

  const materialQueryFn = (query: ItemQuery, filter?: MaterialsFilter) => {
    const params = {
      orderBy: query.orderBy,
      offset: (query.page - 1) * COMPETENCIES_PAGE_SIZE,
      limit: COMPETENCIES_PAGE_SIZE,
    };

    return api.findMaterials(params, filter);
  };

  const [competenciesFilter, setCompetenciesFilter] = useState<CompetenciesFilter>({});

  const competenciesQueryFn = (query: ItemQuery, filter?: CompetenciesFilter) => {
    const params = {
      orderBy: query.orderBy,
      offset: (query.page - 1) * COMPETENCIES_PAGE_SIZE,
      limit: COMPETENCIES_PAGE_SIZE,
    };
    return api.findCompetencies(params, filter);
  };

  const competencyColumns = getCompetencyColumns(subjects, groups);

  return (
    <EntityForm entity={task} onCancel={onCancel} onSubmit={onSubmit} showId>
      <Input name="type" type="hidden" value={type} />

      <Label name={texts.mainLabel}>
        <InputField name="text" required />
      </Label>

      <Label name="Persönlich">
        <SwitchField name="hidden" />
      </Label>

      <Label name="Material">
        <Chooser
          name="materialId"
          transientProp="material"
          title="Material auswählen"
          itemsRenderer={(item) => <MaterialComponent material={item[0]} />}
          filter={materialsFilter}
          filterForm={<MaterialsFilterForm filter={materialsFilter} onChange={setMaterialsFilter} />}
          defaultOrderBy={MATERIALS_DEFAULT_ORDER_BY}
          itemQueryFn={materialQueryFn}
          chooserColumns={materialTableColumns}
          choosenProp="id"
          onShow={() => setMaterialsFilter({})}
        />
      </Label>

      <Label name="Kompetenzen">
        <Chooser
          name="competencyIds"
          transientProp="competencies"
          title="Kompetenzen auswählen"
          itemsRenderer={(items) => <EntityTable className="w-full" columns={competencyColumns} rows={items} />}
          filter={competenciesFilter}
          filterForm={
            <CompetenciesFilterForm
              subjects={subjects}
              groups={groups}
              filter={competenciesFilter}
              onChange={setCompetenciesFilter}
              className="flex-col sm:flex-row"
            />
          }
          defaultOrderBy={COMPETENCIES_DEFAULT_ORDER_BY}
          itemQueryFn={competenciesQueryFn}
          chooserColumns={competencyColumns}
          choosenProp="id"
          onShow={() => setCompetenciesFilter({})}
          chooseMax={Infinity}
        />
      </Label>

      <Label name="Beschreibung">
        <TextareaField name="description" placeholder="Beschreibung" />
      </Label>

      <Label name="Bild">
        <Image name="image" folder="/Aufgaben" width="800" height="800" />
      </Label>
    </EntityForm>
  );
}
