import { format } from 'date-fns';

import { NextCloudImage } from './components/common/NextCloudImage';
import { EntityTableColumn } from './components/EntityTable';

export type ValueOf<T> = T[keyof T];

export function getFilter<T>(query: URLSearchParams) {
  const filter = query.get('filter');
  if (!filter) {
    return;
  }
  try {
    return JSON.parse(filter) as T;
  } catch (err) {
    // noop
  }
}

export function getNewSortQueryString<Data>(column: EntityTableColumn<Data>, queryString: string, defaultOrderBy: string) {
  const query = new URLSearchParams(queryString);
  const { currentDir, newOrderBy, newDir } = getNewColumnSort(column, query.get('orderBy'), defaultOrderBy);
  query.set('orderBy', newOrderBy);

  return {
    queryString: query.toString(),
    currentDir,
    newOrderBy,
    newDir,
  };
}

export function getNewColumnSort<Data>(column: EntityTableColumn<Data>, currentOrderBy?: string | null, defaultOrderBy?: string) {
  const orderBy = currentOrderBy || defaultOrderBy;
  const prop = String(column.prop);

  let newDir = 'asc';
  let currentDir;

  if (orderBy) {
    const [property, dir] = orderBy.split(':');
    if (property === prop) {
      currentDir = dir;
      newDir = currentDir === 'asc' ? 'desc' : 'asc';
    }
  }

  const newOrderBy = `${prop}:${newDir}`;

  return {
    currentDir,
    newOrderBy,
    newDir,
  };
}

export const dateRenderer = (value: Date | string | number | undefined | null) => (value && format(value, 'dd.MM.yyyy')) || '-';
export const dateTimeRenderer = (value: Date | string | number | undefined | null) => (value && format(value, 'dd.MM.yyyy HH:mm')) || '-';

export const thumbnailRenderer = (file: string | undefined | null) =>
  (file && <NextCloudImage className="max-w-16" file={String(file)} alt={String(file)} width="40" height="40" />) || '-';
