import { useLoaderData } from 'react-router';

import type { User } from '../../../../backend/src/model/users/types';
import { StudentTile } from './StudentTile';

type StudentsSelectorProps = {
  onSelect?: (student: User) => void;
};

export function StudentsSelector(props: StudentsSelectorProps) {
  const students = useLoaderData() as User[];

  const { onSelect } = props;

  return (
    <div className="grid gap-6 min-[320px]:grid-cols-1 min-[560px]:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      {students.map((student) => (
        <StudentTile key={student.id} student={student} onClick={() => onSelect && onSelect(student)} />
      ))}
    </div>
  );
}
