import { Input } from '../../../components/common';
import { QueryFilterForm } from '../../../components/common/form/QueryFilterForm';

type Props = {
  className?: string;
};

export default function QueryMaterialsFilterForm(props: Props) {
  const { className } = props;

  return (
    <QueryFilterForm className={className}>
      <Input name="search" placeholder="Suche ..." />
    </QueryFilterForm>
  );
}
