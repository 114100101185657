import type { User } from '@backend/model/users/types';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

const getUser = async () => {
  const response = await fetch('/api/me');
  if (!response.ok) {
    console.warn('Could not fetch user information');
    return null;
  }
  return (await response.json()) as User;
};

type UserState = {
  user: User;
};

const UserStateContext = createContext<UserState | undefined>(undefined);

export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUser();

      setUser(user);
      setLoading(false);
    };

    fetchUser();
  }, []);

  if (loading) {
    // return <div>Loading...</div>;
    return <></>;
  }

  return <UserStateContext.Provider value={{ user }}>{children}</UserStateContext.Provider>;
}

// REVISIT: Do we care and should we maybe always disable this?
// eslint-disable-next-line react-refresh/only-export-components
export function useUser() {
  const state = useContext(UserStateContext);
  if (!state) throw new Error('Missing UserProvider');
  return state.user;
}
