import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import { type Task, TaskType } from '@backend/model/tasks/types';
import type { SerializedDates } from '@backend/model/types';
import type { ServiceResult } from '@backend/service';
import { useLoaderData, useLocation, useNavigate, useRevalidator } from 'react-router';

import { api } from '@/api';
import { Button } from '@/components/common';
import EntityTable, { EntityTableColumn } from '@/components/EntityTable';
import { QueryPagination } from '@/components/QueryPagination';
import { getNewSortQueryString } from '@/helper';
import { useQuery } from '@/hooks/useQuery';
import { useUpdateMessageState } from '@/MessageProvider';

import QueryTasksFilteForm from './components/QueryTasksFilterForm';
import { TASKS_DEFAULT_ORDER_BY, TASKS_PAGE_SIZE } from './loaders';
import { getTasksTableColumns, TASK_TEXTS } from './utils';

export function TasksListPage({ type }: { type: TaskType }) {
  const { tasks, subjects, groups } = useLoaderData() as {
    tasks: ServiceResult<SerializedDates<Task>[]>;
    groups: CompetencyGroup[];
    subjects: Subject[];
  };
  const { result: items, total } = tasks;

  const location = useLocation();
  const query = useQuery();

  const page = Number(query.get('page')) || 1;

  const navigate = useNavigate();
  const getRowOnClickHandler = (clicked: Task) => () => {
    navigate([location.pathname, clicked.id, 'edit'].join('/'));
  };

  const getColumnSort = (column: EntityTableColumn<Task>) => {
    const { currentDir, queryString } = getNewSortQueryString(column, query.toString(), TASKS_DEFAULT_ORDER_BY);
    return {
      sortOnClickHandler: () => navigate(`${location.pathname}?${queryString}`),
      orderDir: currentDir,
    };
  };

  const revalidator = useRevalidator();
  const updateMessageState = useUpdateMessageState();
  const deleteTask = async (taskId: number) => {
    try {
      await api.deleteTask(taskId);
      revalidator.revalidate();
    } catch (error) {
      console.error('deleteTask', error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  const texts = TASK_TEXTS[type];

  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">{texts.listTitle}</h2>
      <div className="mb-8 flex flex-col-reverse justify-end sm:flex-row">
        <QueryTasksFilteForm className="grow flex-col md:flex-row" subjects={subjects} groups={groups} />
        <Button link={[location.pathname, 'new'].join('/')} className="mb-4 sm:mb-0 sm:ml-4">
          {texts.newItem}
        </Button>
      </div>
      {!items.length ? (
        <p>{texts.noItemsFound}</p>
      ) : (
        <>
          <EntityTable
            columns={getTasksTableColumns(type, deleteTask)}
            rows={items}
            className="mb-8 w-full"
            getColumnSort={getColumnSort}
            getRowOnClickHandler={getRowOnClickHandler}
          />
          <div className="flex justify-center">
            <QueryPagination page={page} pageSize={TASKS_PAGE_SIZE} total={total} />
          </div>
        </>
      )}
    </div>
  );
}
