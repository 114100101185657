import { ReactNode, useState } from 'react';

import { cn } from '@/lib/utils';

import { Icon } from '../Icon';

export default function Card({ children, className }: JSX.IntrinsicElements['div'] & { children: ReactNode }) {
  return <div className={`mb-4 rounded border border-black p-4 ${className}`}>{children}</div>;
}

// REVISIT: For some reason React does not like me passing in the <Balloon> component as a title
//          if I would be extending this type by JSX.IntrinsicElements['div'] ¯\_(ツ)_/¯
export type CardColumnProps = {
  title?: ReactNode;
  children?: ReactNode;
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
  expandable?: boolean;
  collapsible?: boolean;
};

export function CardColumn({ title, children, className, contentClassName, titleClassName, expandable, collapsible }: CardColumnProps) {
  const [expanded, setExpanded] = useState(!expandable || collapsible);

  return (
    <div className={className}>
      <div
        className={cn('flex items-center text-xl', (expandable || collapsible) && 'cursor-pointer', titleClassName)}
        onClick={() => {
          if (expandable || collapsible) {
            setExpanded(!expanded);
          }
        }}
      >
        <h3>{title}</h3>
        {(expandable || collapsible) && <Icon name={expanded ? 'arrow_drop_up' : 'arrow_drop_down'} className="inline-block  text-lg" />}
      </div>
      <div
        className={cn(
          (expandable || collapsible) && 'overflow-hidden transition-all duration-300',
          expanded ? 'max-h-full opacity-100' : 'max-h-0 opacity-0',
          contentClassName
        )}
      >
        {children}
      </div>
    </div>
  );
}

export function CardRow({ children, className }: JSX.IntrinsicElements['div'] & { children: ReactNode }) {
  return <div className={`flex gap-4 ${className}`}>{children}</div>;
}

export function CardColumns({ columns }: { columns: CardColumnProps[] }) {
  return (
    <>
      {columns.map((column, i) => (
        <CardColumn key={`Card-column-${i}`} title={column.title}>
          {column.children}
        </CardColumn>
      ))}
    </>
  );
}

export function CardWithColumns({ columns, className }: JSX.IntrinsicElements['div'] & { columns: CardColumnProps[] }) {
  return (
    <Card className={className}>
      <CardRow>
        <CardColumns columns={columns} />
      </CardRow>
    </Card>
  );
}
