import { useLocalStorage } from '@uidotdev/usehooks';
import { createContext, ReactNode, useContext } from 'react';

export type StackItem = {
  label: string;
  pathname: string;
  search: string;
  type?: 'unsaved' | 'back';
  state?: unknown;
};

type Stack = StackItem[];

type StackContextType = {
  stack: Stack;
  stackPush: (item: StackItem) => void;
  stackPop: () => StackItem | undefined;
};

const defaultState: Stack = [];

const StackStateContext = createContext<StackContextType | undefined>(undefined);

export function StackProvider({ children }: { children: ReactNode }) {
  const [stack, updateStack] = useLocalStorage<Stack>('stack', defaultState);

  const stackPush = (item: StackItem) => {
    updateStack((prevStack) => {
      return [...prevStack, item];
    });
  };

  const stackPop = () => {
    if (stack.length === 0) {
      return undefined;
    }
    const newStack = [...stack];
    const item = newStack.pop();
    updateStack(newStack);
    return item;
  };

  return <StackStateContext.Provider value={{ stack, stackPush, stackPop }}>{children}</StackStateContext.Provider>;
}

export function useStack() {
  const state = useContext(StackStateContext);
  if (!state) throw new Error('Missing StackProvider');
  return state;
}
