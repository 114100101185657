import type { Competency, CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import { TaskType } from '@backend/model/tasks/types';

import Card, { CardColumn, CardRow } from '@/components/common/Card';
import EntityTable from '@/components/EntityTable';
import { Star } from '@/components/Icon';
import { cn } from '@/lib/utils';

type WithSubjectAndGroup<T> = T & { subject: Subject; group: CompetencyGroup };

type Props = {
  competency: WithSubjectAndGroup<Competency & { rating?: number }>;
  onChangeRating?: (competencyId: number, rating: number) => void;
};

export default function CompetencyCard(props: Props) {
  const { competency, onChangeRating } = props;
  const { rating } = competency;
  const ratingClass = (star: number) => (rating && rating >= star ? 'fill-current' : 'fill-none');

  const starBaseClasses = 'w-8 cursor-pointer stroke-current stroke-2 p-0.5 text-fuxs-orange-dark transition-all hover:fill-current';

  const onClick = (star: number) => {
    let newRating = star;
    if (star === 1 && rating === 1) {
      newRating = 0;
    }
    console.log(`New rating for ${competency.id}`, newRating);
    onChangeRating?.(competency.id, newRating);
  };

  // console.log('CompetencyCard', competency, rating);

  function CardTitle() {
    return (
      <span className="flex gap-1">
        <span>Kompetenz</span>
        <span className="text-gray-light">
          - {competency.subject.label} - {competency.group.label}
        </span>
      </span>
    );
  }

  return (
    <Card>
      <CardRow className="mb-1 flex-col justify-between  xs:flex-row">
        <CardColumn title={<CardTitle />} titleClassName="text-sm">
          {competency.label}
        </CardColumn>
        <CardColumn
          title="Einschätzung"
          className="justify-center"
          titleClassName="text-sm justify-center mb-0.5"
          contentClassName="flex justify-center flex-row-reverse"
        >
          <Star className={cn(starBaseClasses, 'peer/three', ratingClass(3))} onClick={() => onClick(3)} />
          <Star
            className={cn(starBaseClasses, 'peer/two', ratingClass(2), 'hover:fill-current peer-hover/three:fill-current')}
            onClick={() => onClick(2)}
          />
          <Star
            className={cn(
              starBaseClasses,
              'peer/one',
              ratingClass(1),
              'hover:fill-current peer-hover/three:fill-current peer-hover/two:fill-current'
            )}
            onClick={() => onClick(1)}
          />
        </CardColumn>
      </CardRow>
      {competency.tasks && competency.tasks.length > 0 && (
        <CardRow className="flex-col">
          <CardColumn title="Aufgaben" titleClassName="text-sm" expandable>
            <EntityTable
              className="w-full"
              columns={[
                {
                  label: 'ID',
                  prop: 'id',
                  sortable: true,
                  showSize: 'md',
                },
                {
                  label: 'Typ',
                  prop: 'type',
                  renderer: (type) => <span>{type === TaskType.Normal ? 'Aufgabe' : 'Projekt'}</span>,
                },
                {
                  label: 'Aufgabe / Projekt',
                  prop: 'text',
                  renderer: (text) => (
                    <p className="line-clamp-2" title={text}>
                      {text}
                    </p>
                  ),
                },
              ]}
              rows={competency.tasks}
            />
          </CardColumn>
        </CardRow>
      )}
    </Card>
  );
}
