import { useLoaderData, useNavigate, useParams } from 'react-router';

import type { Plan } from '../../../../backend/src/model/plans/types';
import type { SerializedDates } from '../../../../backend/src/model/types';
import type { User } from '../../../../backend/src/model/users/types';
import type { ServiceResult } from '../../../../backend/src/service';
import { Button } from '../../components/common';
import { StudentsSelector } from '../components/StudentsSelector';
import { StudentPlans } from './components/StudentPlans';

export function StudentsPlansPage() {
  const { studentId } = useParams();
  const { student, plans } = useLoaderData() as { student: User; plans?: ServiceResult<SerializedDates<Plan>[]> };

  const navigate = useNavigate();
  const onSelectStudent = (student: User) => {
    navigate([location.pathname, student.id].join('/'));
  };

  return (
    <div>
      <h2 className="mb-4 text-2xl font-bold">Arbeitspläne{studentId ? ` für ${student.displayName}` : ''}</h2>
      <div className="mb-4 flex justify-end">{studentId && <Button link={[location.pathname, 'new'].join('/')}>Neuer Plan</Button>}</div>
      {!studentId && <StudentsSelector onSelect={onSelectStudent} />}

      {plans && <StudentPlans plans={plans} />}
    </div>
  );
}
