import { JSX } from 'react';

import { Button } from './common';
import { Icon } from './Icon';
import Logo from './Logo';

export type WelcomeProps = {
  userName?: string;
};
export default function Welcome(props: WelcomeProps): JSX.Element {
  const { userName } = props;
  return (
    <div className="mx-auto flex h-full max-w-screen-md flex-col items-center justify-center pt-10">
      <h1 className="mb-4 text-2xl font-bold">👋🏽</h1>
      <h1 className="mb-4 text-2xl font-bold">Willkommen</h1>
      {userName && <h1 className="mb-4 text-2xl font-bold">{userName}</h1>}
      <h2 className="mt-2 text-xl">auf der</h2>
      <Logo width="128" />
      <h1 className="mb-8 mt-2 text-2xl font-bold">Plattform</h1>
      <Button href="/login">
        Anmelden <Icon className="ml-2" name="login" />
      </Button>
    </div>
  );
}
