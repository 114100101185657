import { ComponentProps } from 'react';
import { DeepPartial, DefaultValues, FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { getFilter } from '../../../helper';
import { FilterForm } from './FilterForm';

type QueryFilterFormProps = ComponentProps<typeof FilterForm>;

export function QueryFilterForm<T extends FieldValues>(props: QueryFilterFormProps) {
  const { children, className } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const filter = getFilter<T>(searchParams) as DefaultValues<T>;

  const onChange = (value: DeepPartial<T>) => {
    searchParams.set('filter', JSON.stringify(value));
    // NOTE: Resets the page to start at the beginning when filtering
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  return (
    <FilterForm className={`flex flex-row gap-4 ${className}`} filter={filter} onChange={onChange}>
      {children}
    </FilterForm>
  );
}
