import { ReactNode } from 'react';

import { Icon } from './Icon';

const range = (start: number, stop: number, step = 1) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

function PaginationLink({ onClick, children }: { onClick: () => void; children: ReactNode }) {
  return (
    <li className="rounded border-2 border-black">
      <a className="hover:bg-fuxs-orange-light flex cursor-pointer items-start bg-white px-2 py-1" onClick={onClick}>
        {children}
      </a>
    </li>
  );
}

type Props = {
  page?: number;
  total?: number;
  pageSize?: number;
  onChangePage: (page: number) => void;
};

export function Pagination({ page = 1, pageSize = 1, total = 0, onChangePage }: Props) {
  const pages = Math.ceil(total / pageSize);
  if (pages === 1) {
    return <></>;
  }

  const prevPage = page > 1 ? page - 1 : null;
  const nextPage = page < pages ? page + 1 : null;

  const prevPages = range(page - 5, page - 1).filter((v) => v >= 1);
  const nextPages = range(page + 1, pages).slice(0, 5);

  return (
    <div className="mb-4 flex flex-row items-center gap-2">
      <ul className="flex flex-row gap-1">
        {prevPage && (
          <PaginationLink onClick={() => onChangePage(prevPage)}>
            <Icon name="navigate_before" className="mt-1 text-lg font-bold" /> Zurück
          </PaginationLink>
        )}
        {prevPages.map((p) => (
          <PaginationLink key={p} onClick={() => onChangePage(p)}>
            {p}
          </PaginationLink>
        ))}
        <li className="bg-fuxs-orange rounded border-2 border-black text-white">
          <p className="inline-block px-2 py-1 font-bold">{page}</p>
        </li>
        {nextPages.map((p) => (
          <PaginationLink key={p} onClick={() => onChangePage(p)}>
            {p}
          </PaginationLink>
        ))}
        {nextPage && (
          <PaginationLink onClick={() => onChangePage(nextPage)}>
            Weiter <Icon name="navigate_next" className="mt-1 text-lg font-bold" />
          </PaginationLink>
        )}
      </ul>
    </div>
  );
}
