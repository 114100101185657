import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import type { Task, TaskType, TaskWithCompetencies } from '@backend/model/tasks/types';
import type { SerializedDates } from '@backend/model/types';
import { useLoaderData, useNavigate } from 'react-router';

import { api } from '@/api';
import { useUpdateMessageState } from '@/MessageProvider';

import TaskForm from './components/TaskForm';
import { TASK_TEXTS } from './utils';

export function EditTaskPage({ type }: { type: TaskType }) {
  const { task, subjects, groups } = useLoaderData() as {
    task: SerializedDates<TaskWithCompetencies>;
    groups: CompetencyGroup[];
    subjects: Subject[];
  };

  const texts = TASK_TEXTS[type];

  const navigate = useNavigate();
  function onCancel() {
    navigate('../..');
  }

  const updateMessageState = useUpdateMessageState();
  const saveForm = async (task: Task) => {
    console.log('Saving', task);
    try {
      await api.updateTask(task);
      updateMessageState({
        text: texts.editSaveSuccess,
        level: 'success',
      });
      navigate('..');
    } catch (error: unknown) {
      console.log(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">{texts.editItem}</h1>
      <TaskForm type={type} task={task} subjects={subjects} groups={groups} onSubmit={saveForm} onCancel={onCancel} />
    </div>
  );
}
