import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';

import { combinePropertyLoaders, extractFindParamsFromRequest, LoaderContext } from '@/lib/loaders.ts';

import { api } from '../api';
import { getFilter } from '../helper';
import { COMPETENCIES_DEFAULT_ORDER_BY, COMPETENCIES_PAGE_SIZE } from './competencies/CompentenciesListPage';
import { GROUPS_DEFAULT_ORDER_BY, GROUPS_PAGE_SIZE } from './groups/GroupsListPage';

export async function groupsLoader({ request }: { request: Request }) {
  const { findParams, query } = extractFindParamsFromRequest(request, GROUPS_DEFAULT_ORDER_BY, GROUPS_PAGE_SIZE);
  return await api.findCompetencyGroups(findParams, getFilter(query));
}

export async function competenciesLoader({ request }: { request: Request }) {
  const { findParams, query } = extractFindParamsFromRequest(request, COMPETENCIES_DEFAULT_ORDER_BY, COMPETENCIES_PAGE_SIZE);
  return { competencies: await api.findCompetencies(findParams, getFilter(query)) };
}

export async function studentsCompetenciesLoader(context: LoaderContext) {
  const {
    request,
    params: { studentId },
  } = context;
  const { findParams, query } = extractFindParamsFromRequest(request, COMPETENCIES_DEFAULT_ORDER_BY, COMPETENCIES_PAGE_SIZE);
  return { competencies: await api.findStudentCompetencies(Number(studentId), findParams, getFilter(query)) };
}

export async function allSubjectsLoader() {
  return { subjects: (await api.findSubjects({ orderBy: 'label' })).result };
}

export async function allGroupsLoader() {
  return { groups: (await api.findCompetencyGroups({ orderBy: 'label' })).result };
}

export type SubjectsAndGroups = {
  subjects: Subject[];
  groups: CompetencyGroup[];
};

export function subjectsAndGroupsLoader(context: LoaderContext): Promise<SubjectsAndGroups> {
  return combinePropertyLoaders(allSubjectsLoader, allGroupsLoader)(context);
}
