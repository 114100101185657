import { Params } from 'react-router';

import { api } from '../../api';

export const SUBJECTS_DEFAULT_ORDER_BY = 'id:asc';
export const SUBJECTS_PAGE_SIZE = 5;

export async function subjectLoader({ params }: { params: Params }) {
  return await api.fetchSubject(Number(params.id));
}

export async function subjectsLoader({ request }: { request: Request }) {
  const query = new URL(request.url).searchParams;
  const page = Number(query.get('page')) || 1;
  const params = {
    orderBy: query.get('orderBy') || SUBJECTS_DEFAULT_ORDER_BY,
    offset: (page - 1) * SUBJECTS_PAGE_SIZE,
    limit: SUBJECTS_PAGE_SIZE,
  };
  return await api.findSubjects(params);
}
