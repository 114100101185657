import { Outlet } from 'react-router';

import { useUser } from '@/UserProvider';

import Message from '../components/common/Message';
import { TabNav, TabNavPropsItem } from '../components/TabNav';
import TopBar from '../components/TopBar';

export function CurriculumPage() {
  const user = useUser();

  const tabNav: TabNavPropsItem[] = [
    {
      label: 'Fächer',
      link: '/curriculum/subjects',
    },
    {
      label: 'Gruppen / Teilbereiche',
      link: '/curriculum/groups',
    },
    {
      label: 'Kompetenzen',
      link: '/curriculum/competencies',
    },
  ];

  return (
    <div>
      <TopBar user={user} />

      <div className="mx-auto flex h-full flex-col p-4">
        <Message />

        <h1 className="mb-4 text-3xl font-bold">Einstellungen</h1>
        <TabNav className="mb-8 flex-col xs:flex-row" items={tabNav} />
        <Outlet />
      </div>
    </div>
  );
}
