import { useEffect } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router';

import type { Plan, PlanTask, PlanWithTasks, UserPlanTask } from '../../../../backend/src/model/plans/types';
import { type Task, TaskType } from '../../../../backend/src/model/tasks/types';
import { api } from '../../api';
import type { SubjectsAndGroups } from '../../curriculum/loaders';
import { useUpdateMessageState } from '../../MessageProvider';
import { useStack } from '../../StackProvider';
import PlanForm from './components/PlanForm';
import { splitProjectsAndTasks } from './utils';

export function EditPlanPage() {
  const { plan, ...subjectAndGroups } = useLoaderData() as SubjectsAndGroups & { plan: PlanWithTasks };

  const { studentId } = useParams();
  if (!studentId) {
    throw new Error('No studentId provided');
  }

  const navigate = useNavigate();

  // NOTE: StudenSelector was changed
  useEffect(() => {
    if (studentId !== plan.userId.toString()) {
      console.warn(`Cancelling editing plan of student ${plan.userId} and switching to ${studentId}`);
      navigate(`../${studentId}`);
    }
  }, [studentId, plan.userId, navigate]);

  function onCancel() {
    navigate(`../${studentId}`);
  }

  const updateMessageState = useUpdateMessageState();
  const saveForm = async (data: Plan & { tasks: (Task | UserPlanTask)[]; projects: (Task | UserPlanTask)[] }) => {
    console.log('Having', data);
    const exposeTaskId = (task: Task | PlanTask) => {
      if ('taskId' in task) {
        return task;
      }
      return {
        taskId: task.id,
      };
    };

    const { id, target, tasks, projects } = data;
    const payload = {
      id,
      target,
      tasks: [...tasks.map(exposeTaskId), ...projects.map(exposeTaskId)],
    };

    console.log('Saving', payload);

    try {
      await api.updatePlan(payload);
      updateMessageState({
        text: 'Der neue Plan wurde erfolgreich gespeichert',
        level: 'success',
      });

      navigate(`../${studentId}`);
    } catch (error) {
      console.error(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  const location = useLocation();
  const { stackPush } = useStack();
  const onNewTask = (type: TaskType, plan: Plan) => {
    const { pathname, search } = location;
    stackPush({
      label: `Arbeitsplan für ${plan.user.displayName} bearbeiten`,
      pathname,
      search,
      type: 'unsaved',
      state: plan,
    });
    if (type === TaskType.Project) {
      navigate('/entities/projects/new');
      return;
    }
    navigate('/entities/tasks/new');
  };

  const formPlan: PlanWithTasks & { projects: UserPlanTask[] } = splitProjectsAndTasks(location.state || plan);

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Arbeitsplan für {formPlan.user.displayName} bearbeiten</h1>
      <PlanForm
        plan={formPlan}
        {...subjectAndGroups}
        studentId={Number(studentId)}
        onSubmit={saveForm}
        onCancel={onCancel}
        onNewtTask={onNewTask}
      />
    </div>
  );
}
