import { tw } from 'classname-variants';
import { useEffect } from 'react';

import { useCombinedMessageState } from '../../MessageProvider';
import { Icon } from '../Icon';

export type MessageLevel = 'success' | 'info' | 'warning' | 'error';

export type MessageState = { text: string; level: MessageLevel };

export default function Message() {
  const [state, setState] = useCombinedMessageState();

  const hide = () => {
    if (state.text !== '') {
      setState({
        ...state,
        text: '',
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const timeoutId = setTimeout(hide, 5000);
    return () => clearTimeout(timeoutId);
  }, [state]);

  // if (!state.text) {
  //   return <></>;
  // }

  const messageStyle = (level: MessageLevel | undefined) => {
    switch (level) {
      case 'success':
        return tw`bg-ok-green`;
      case 'info':
        return tw`bg-info-blue`;
      case 'warning':
        return tw`bg-info-blue`;
      case 'error':
        return tw`bg-error-red`;
      default:
        return tw`bg-info-blue`;
    }
  };

  const display = state.text ? '' : tw`-z-10 mb-0 h-0 py-0 opacity-0`;

  return (
    <div className={`transition-all ${display}`}>
      <div className={`flex items-center gap-1 ${messageStyle(state.level)} mx-[-8px] mb-4 rounded p-2`}>
        <Icon className="pt-px text-xl" name="check_circle" />
        <p className="grow">{state.text}</p>
        <a className="flex cursor-pointer items-center" onClick={hide}>
          <Icon className="pt-px text-xl font-black" name="close" />
        </a>
      </div>
    </div>
  );
}
