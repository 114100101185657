import { JSX, useState } from 'react';
import { useLocation } from 'react-router';

import { isStudent, isTeacher } from '../../../backend/src/model/users/helper';
import type { User } from '../../../backend/src/model/users/types';
import Logo from '../components/Logo';
import { useOnOutsideClick } from '../hooks/useOutsideClick';
import { ModalPortal } from './common/ModalPortal';
import { Icon } from './Icon';
import NavItem from './NavItem';

type NavigationProps = JSX.IntrinsicElements['nav'] & {
  user?: User;
};

export default function Navigation(props: NavigationProps) {
  const location = useLocation();
  const { user } = props;

  const [expanded, setExpanded] = useState<boolean>(false);
  const { innerBorderRef } = useOnOutsideClick(() => setExpanded(false));

  const isActive = (prefix: string, exact = false) => {
    const uri = location.pathname;
    if (exact) return uri === prefix;
    return uri.startsWith(prefix);
  };

  if (!user || (!isTeacher(user) && !isStudent(user))) {
    return (
      <span className="flex items-center gap-2 px-2">
        <a href="/" className="flex">
          <Logo className="mt-[-3px]" width="32" />
        </a>
      </span>
    );
  }

  function LogoLink() {
    return (
      <a href="/" className="flex h-[40px] w-[32px]">
        <Logo className="mt-[-3px]" width="32" />
      </a>
    );
  }

  function LogoExpand({ children, className, expanded }: { children?: JSX.Element; className?: string; expanded?: boolean }) {
    return (
      <span className={`flex items-center gap-2 px-2 ${className}`}>
        <LogoLink />

        {expanded && (
          <>
            <span className="">Freie Schule FuXs</span>
            <span className="flex-grow" />
          </>
        )}

        <a onClick={() => setExpanded(!expanded)} className="flex cursor-pointer hover:text-fuxs-orange">
          <Icon name={expanded ? 'left_panel_close' : 'left_panel_open'} />
        </a>

        {children}
      </span>
    );
  }

  function Nav() {
    return (
      <ModalPortal>
        <aside className="fixed left-0 top-0 z-10" ref={innerBorderRef}>
          <nav className="min-h-screen border-r-2 border-black bg-white p-2">
            <LogoExpand className="mb-2" expanded={true} />

            {isStudent(user) && (
              <ul className="flex flex-col gap-2 p-2">
                <NavItem link="/" active={isActive('/', true)}>
                  <Icon name="desk" />
                  Schreibtisch
                </NavItem>
                <NavItem link="/plan" active={isActive('/plan')}>
                  <Icon name="inventory" />
                  Arbeitsplan
                </NavItem>
                <NavItem link="/report" active={isActive('/report')}>
                  <Icon name="description" />
                  Tagesbericht
                </NavItem>
                <NavItem link="/path" active={isActive('/paths')}>
                  <Icon name="conversion_path" />
                  FuXs Pfade
                </NavItem>
                <NavItem link="" active={false}>
                  <Icon name="groups" />
                  Zuständige
                </NavItem>
                <NavItem link="https://www.fuxs.schule/" target="_blank" active={false}>
                  <Logo className="" width="16" />
                  FuXs Schule Website
                </NavItem>
                <NavItem link="https://www.blinde-kuh.de/index.html" target="_blank" active={false}>
                  <Icon name="search" />
                  Suche
                </NavItem>
                <NavItem link="/game" active={isActive('/game')}>
                  <Icon name="robot" />
                  Lernspiel
                </NavItem>
              </ul>
            )}

            {isTeacher(user) && (
              <ul className="flex flex-col gap-2 p-2">
                <NavItem link="/" active={isActive('/', true)}>
                  <Icon name="desk" />
                  Schreibtisch
                </NavItem>
                <NavItem link="/students/plans" active={isActive('/students/plans')}>
                  <Icon name="inventory" />
                  Arbeitspläne
                </NavItem>
                <NavItem link="/students/reports" active={isActive('/students/reports')}>
                  <Icon name="description" />
                  Tagesberichte
                </NavItem>
                <NavItem link="/students/competencies" active={isActive('/students/competencies')}>
                  <Icon name="checklist" />
                  Kompetenzen
                </NavItem>
                <NavItem link="/paths" active={isActive('/paths')}>
                  <Icon name="conversion_path" />
                  FuXs Pfade
                </NavItem>
                <NavItem link="" active={false}>
                  <Icon name="groups" />
                  Zuständige
                </NavItem>
                <NavItem link="https://www.fuxs.schule/" target="_blank" active={false}>
                  <Logo className="" width="16" />
                  FuXs Schule Website
                </NavItem>
                <NavItem link="/entities" active={isActive('/entities')}>
                  <Icon name="book_2" />
                  Sammlung / Katalog
                </NavItem>
                <NavItem link="/curriculum" active={isActive('/curriculum')}>
                  <Icon name="apps" />
                  Kern-Curriculum
                </NavItem>
              </ul>
            )}
          </nav>
        </aside>
      </ModalPortal>
    );
  }

  return <LogoExpand>{(expanded && <Nav />) || <></>}</LogoExpand>;
}
