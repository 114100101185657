import { JSX } from 'react';

export function Label({ name, children }: JSX.IntrinsicElements['label'] & { name: string }) {
  return (
    <label className="">
      <span className="">{name}</span>
      <span className="flex flex-col">{children}</span>
    </label>
  );
}
