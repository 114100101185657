import type { CompetenciesFilter, CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import { DeepPartial } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { getFilter } from '@/helper';

import CompetenciesFilteForm from './CompetenciesFilterForm';

type Props = {
  subjects: Subject[];
  groups: CompetencyGroup[];
  className?: string;
  children?: React.ReactNode;
};

export default function QueryCompetenciesFilterForm(props: Props) {
  const { className, subjects, groups, children } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const filter = getFilter<CompetenciesFilter>(searchParams);

  const onChange = (value: DeepPartial<CompetenciesFilter>) => {
    searchParams.set('filter', JSON.stringify(value));
    // NOTE: Resets the page to start at the beginning when filtering
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  return (
    <CompetenciesFilteForm className={className} subjects={subjects} groups={groups} filter={filter} onChange={onChange}>
      {children}
    </CompetenciesFilteForm>
  );
}
