import { SubmitHandler } from 'react-hook-form';

import type { Material, NewMaterial } from '../../../../../backend/src/model/materials/types';
import { MaterialType } from '../../../../../backend/src/model/materials/types';
import { Image, Input } from '../../../components/common';
import { Dropdown } from '../../../components/common/form/Dropdown';
import { EntityForm } from '../../../components/common/form/EntityForm';
import { Label } from '../../../components/common/form/Label';
import { getMaterialTypeLabel } from '../utils';

export type MaterialFormProps = {
  material?: Partial<NewMaterial>;
  onSubmit: SubmitHandler<Material>;
  onCancel?: () => void;
};

export default function SubjectForm(props: MaterialFormProps) {
  const { onCancel, onSubmit, material } = props;

  const showUrl = material?.type !== MaterialType.Book || material?.type !== MaterialType.Other;

  return (
    <EntityForm entity={material} onCancel={onCancel} onSubmit={onSubmit} showId>
      <Label name="Materialtyp">
        <Dropdown
          name="type"
          entries={[
            { value: '', label: 'Auswählen ...' },
            ...Object.values(MaterialType).map((value) => ({ value, label: getMaterialTypeLabel(value) })),
          ]}
          required
        />
      </Label>

      {/* TODO: Make field types dependent on the selected material type */}

      <Label name="Name">
        <Input name="label" placeholder="Name" required />
      </Label>

      <Label name="Beschreibung">
        <Input name="description" placeholder="Beschreibung" />
      </Label>

      <Label name="Ort">
        <Input name="location" placeholder="Das Material befindet sich ..." />
      </Label>

      {showUrl && (
        <Label name="Url">
          <Input name="url" placeholder="https://..." />
        </Label>
      )}

      <Label name="Bild">
        <Image name="image" folder="/Material" width="800" height="800" />
      </Label>
    </EntityForm>
  );
}
