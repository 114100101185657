import { Params } from 'react-router';

import type { PlanDetails, PlanWithTasks } from '../../../backend/src/model/plans/types.ts';
import type { User } from '../../../backend/src/model/users/types.ts';
import { api } from '../api.ts';

export const PLANS_DEFAULT_ORDER_BY = 'createdAt:desc';
export const PLANS_PAGE_SIZE = 25;

export async function studentLoader({ params }: { params: Params }): Promise<{ student: User }> {
  return { student: await api.fetchUser(Number(params.studentId)) };
}

export type PlanWithTaksAndUser = PlanWithTasks & { user: User };

// REVISIT: Split out userLoader and use with combinePropertyLoaders in ':studentId/:id/edit' route
export async function planLoader({ params }: { params: Params }): Promise<{ plan: PlanWithTaksAndUser }> {
  const plan = await api.fetchPlan(Number(params.id));
  if (!plan) {
    throw new Error('Not found');
  }
  const user = await api.fetchUser(plan.userId);
  return {
    plan: {
      ...plan,
      user,
    },
  };
}

export async function detailPlanLoader({ params }: { params: Params }): Promise<{ plan: PlanDetails }> {
  return { plan: await api.fetchPlanDetails(Number(params.id)) };
}

export async function plansLoader({ params, request }: { params: Params; request: Request }) {
  const { studentId } = params;
  const query = new URL(request.url).searchParams;
  const page = Number(query.get('page')) || 1;
  const findParams = {
    orderBy: query.get('orderBy') || PLANS_DEFAULT_ORDER_BY,
    offset: (page - 1) * PLANS_PAGE_SIZE,
    limit: PLANS_PAGE_SIZE,
  };
  return await api.findStudentPlans(Number(studentId), findParams);
}
