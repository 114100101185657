import { CSSProperties } from 'react';

type NextCloudImageProps = {
  file: string;
  width?: string;
  height?: string;
  alt?: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

// const NEXT_CLOUD_PREVIEW_URL = 'https://cloud.fuxs.schule/core/preview.png?file=';
// const NEXT_CLOUD_FILES_URL = 'https://cloud.fuxs.schule/files';

export function NextCloudImage(props: NextCloudImageProps) {
  const { file, width, height, alt, ...rest } = props;

  const query = new URLSearchParams({ file });
  if (width) {
    query.set('width', width);
  }
  if (height) {
    query.set('height', height);
  }

  const url = `/api/files?${query.toString()}`;

  // REVISIT: This could be improved
  const styles: CSSProperties = {};
  if (width || height) {
    styles.minWidth = `${Math.floor(Math.max(Number(height || 0), Number(width || 0), 80) / 2)}px`;
    // styles.maxWidth = `${Math.floor(Math.max(Number(height || 0), Number(width || 0), 160) * 2)}px`;
  }

  return <img style={styles} src={url} alt={alt || file} {...rest} />;
}
