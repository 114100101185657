import { JSX } from 'react';

export type TextLinkProps = JSX.IntrinsicElements['a'];

export function TextLink(props: TextLinkProps) {
  const { href, children, ...rest } = props;

  return (
    <a
      className="inline-block cursor-pointer underline decoration-fuxs-logo-yellow decoration-2 transition-all hover:bg-fuxs-orange-light"
      href={href}
      {...rest}
    >
      {children}
    </a>
  );
}
