import { ComponentProps } from 'react';
import { useNavigate } from 'react-router';

import { useQuery } from '../hooks/useQuery';
import { Pagination } from './Pagination';

type QueryPaginationProps = Omit<ComponentProps<typeof Pagination>, 'onChangePage'>;

export function QueryPagination(props: QueryPaginationProps) {
  const query = useQuery();
  const navigate = useNavigate();

  const onChangePage = (page: number) => {
    if (page === 1) {
      query.delete('page');
    } else {
      query.set('page', String(page));
    }
    const qs = query.toString();

    navigate(`${qs ? `?${qs}` : ''}`);
  };

  return <Pagination onChangePage={onChangePage} {...props} />;
}
