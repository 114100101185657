import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

import { MessageState } from './components/common/Message';

const defaultState: MessageState = { text: '', level: 'success' };

type UpdateMessageState = Dispatch<SetStateAction<MessageState>>;

const MessageStateContext = createContext<MessageState>(defaultState);
const UpdateMessageStateContext = createContext<UpdateMessageState | null>(null);

export function MessageProvider({ children }: { children: ReactNode }) {
  const [messageState, updateMessageState] = useState<MessageState>(defaultState);

  return (
    <MessageStateContext.Provider value={messageState}>
      <UpdateMessageStateContext.Provider value={updateMessageState}>{children}</UpdateMessageStateContext.Provider>
    </MessageStateContext.Provider>
  );
}

export function useMessageState() {
  const state = useContext(MessageStateContext);
  if (!state) throw new Error('Missing MessageProvider');
  return state;
}

export function useUpdateMessageState() {
  const updateState = useContext(UpdateMessageStateContext);
  if (!updateState) throw new Error('Missing MessageProvider');
  return updateState;
}

export function useCombinedMessageState(): [MessageState, UpdateMessageState] {
  return [useMessageState(), useUpdateMessageState()];
}
