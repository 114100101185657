import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { UserPlanTask } from '@backend/model/plans/types';
import type { Subject } from '@backend/model/subjects';
import { TaskType } from '@backend/model/tasks/types';
import type { User } from '@backend/model/users/types';
import { useLocation, useNavigate } from 'react-router';

import { Checkbox } from '@/components/ui/checkbox';
import { useStack } from '@/StackProvider';

import { getCompetencyColumns } from '../curriculum/competencies/utils';
import Balloon from './common/Balloon';
import Card, { CardColumn, CardRow } from './common/Card';
import { NextCloudImage } from './common/NextCloudImage';
import OpenInOverlay from './common/OpenInOverlay';
import EntityTable from './EntityTable';
import { Icon } from './Icon';

export default function TaskCard({
  position,
  task,
  student,
  subjects,
  groups,
  toggleTaskFinished,
  toggleTaskConfirmed,
}: {
  position: number;
  task: UserPlanTask;
  student: User;
  subjects?: Subject[];
  groups?: CompetencyGroup[];
  toggleTaskFinished?: (taskId: number) => Promise<void>;
  toggleTaskConfirmed?: (taskId: number) => Promise<void>;
}) {
  const navigate = useNavigate();

  const location = useLocation();
  const { stackPush } = useStack();
  const openCompetency = (id: number) => {
    const { pathname, search } = location;
    stackPush({
      label: `Arbeitsplan von ${student.displayName}`,
      pathname,
      search,
    });
    const filter = JSON.stringify({ competencyId: id });
    navigate(`../../competencies/${student.id}?filter=${filter}`);
  };

  const competencyColumns =
    (subjects &&
      groups && [
        ...getCompetencyColumns(subjects, groups),
        {
          label: '',
          prop: 'id',
          renderer: (id: number) => <Icon name="open_in_new" className="cursor-pointer" onClick={() => openCompetency(id)} />,
          centered: true,
        },
      ]) ||
    null;

  const toggleTask = (taskId: number, key: 'finished' | 'confirmed') => {
    if (key === 'finished' && toggleTaskFinished) {
      return toggleTaskFinished(taskId);
    }
    if (key === 'confirmed' && toggleTaskConfirmed) {
      return toggleTaskConfirmed(taskId);
    }
  };

  return (
    <Card>
      <CardRow className="mb-4 flex-col justify-between sm:flex-row">
        <div className="flex gap-4">
          <div>
            <Balloon>{position}</Balloon>
          </div>
          <CardColumn className="grow" title={task.type === TaskType.Normal ? 'Aufgabentitel' : 'Projektname'} titleClassName="text-sm">
            {task.text}
          </CardColumn>
        </div>
        <CardRow className="flex-row justify-center">
          <CardColumn title="Gemacht" titleClassName="text-sm" className="flex flex-col items-center gap-1">
            <div className="flex justify-center">
              <Checkbox
                defaultChecked={task.finished}
                onCheckedChange={() => toggleTask(task.id, 'finished')}
                disabled={!toggleTaskFinished}
              />
            </div>
          </CardColumn>
          <CardColumn title="Bestätigt" titleClassName="text-sm" className="flex flex-col items-center gap-1">
            <div className="flex justify-center">
              <Checkbox
                defaultChecked={task.confirmed}
                onCheckedChange={() => toggleTask(task.id, 'confirmed')}
                disabled={!toggleTaskConfirmed}
              />
            </div>
          </CardColumn>
        </CardRow>
      </CardRow>

      <CardRow className="mb-4 flex-col last:mb-0 sm:flex-row">
        {task.image && (
          <CardColumn title="Bild" titleClassName="text-sm">
            <OpenInOverlay preview={<NextCloudImage className="max-h-60" file={task.image} />}>
              <NextCloudImage file={task.image} />
            </OpenInOverlay>
          </CardColumn>
        )}
        {task.material && task.material.image && (
          <CardColumn title="Material" titleClassName="text-sm">
            <OpenInOverlay preview={<NextCloudImage className="max-h-60" file={task.material.image} />}>
              <NextCloudImage file={task.material.image} />
            </OpenInOverlay>
          </CardColumn>
        )}
      </CardRow>

      {task.description && (
        <CardRow className="mb-4 last:mb-0">
          <CardColumn
            title={task.type === TaskType.Normal ? 'Aufgabenbeschreibung' : 'Projektbeschreibung'}
            titleClassName="text-sm"
            collapsible
          >
            {task.description}
          </CardColumn>
        </CardRow>
      )}

      {task.material && (task.material.label || task.material.description) && (
        <CardRow className="mb-4 last:mb-0">
          {task.material.label && (
            <CardColumn title="Material" titleClassName="text-sm" expandable>
              {task.material.label && <p>{task.material.label}</p>}
              {task.material.description && <p>{task.material.description}</p>}
              {task.material.location && <p>{task.material.location}</p>}
              {task.material.url && (
                <a className="underline" href={task.material.url} target="_blank" rel="noreferrer">
                  {task.material.url}
                </a>
              )}
            </CardColumn>
          )}
        </CardRow>
      )}

      {competencyColumns && task.competencies && task.competencies.length > 0 && (
        <CardRow className="mb-4 last:mb-0">
          <CardColumn title="Kompetenzen" className="w-full" titleClassName="text-sm" expandable>
            {<EntityTable columns={competencyColumns} rows={task.competencies} className="w-full" />}
          </CardColumn>
        </CardRow>
      )}
    </Card>
  );
}
