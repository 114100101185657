import { useState } from 'react';

export function useErrorBoundary<T extends unknown[]>(callback: (...args: T) => Promise<void>) {
  const [, bubbleUp] = useState();
  return async (...args: T) => {
    try {
      await callback(...args);
    } catch (err) {
      bubbleUp(() => {
        throw err;
      });
    }
  };
}
