import { useLoaderData, useNavigate } from 'react-router-dom';

import type { Material } from '../../../../backend/src/model/materials/types';
import type { SerializedDates } from '../../../../backend/src/model/types';
import { api } from '../../api';
import { useUpdateMessageState } from '../../MessageProvider';
import MaterialForm from './components/MaterialForm';

export function EditMaterialPage() {
  const material = useLoaderData() as SerializedDates<Material>;
  const navigate = useNavigate();
  const updateMessageState = useUpdateMessageState();

  function onCancel() {
    navigate('../..');
  }

  const saveForm = async (material: Material) => {
    console.log('Saving', material);
    try {
      await api.updateMaterial(material);
      updateMessageState({
        text: 'Das Material wurde erfolgreich gespeichert.',
        level: 'success',
      });
      navigate('..');
    } catch (error: unknown) {
      console.log(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Material editieren</h1>
      <MaterialForm material={material} onSubmit={saveForm} onCancel={onCancel} />
    </div>
  );
}
