import { tw } from 'classname-variants';
import { JSX } from 'react';
import { FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';

export type InputProps<T extends FieldValues> = JSX.IntrinsicElements['input'] & {
  name: FieldPath<T>;
  fieldOptions?: RegisterOptions<T>;
};

export function Input<T extends FieldValues>(props: InputProps<T>) {
  const { className, name, type = 'text', disabled, readOnly, fieldOptions, ...rest } = props;

  const {
    register,
    // formState: { errors },
  } = useFormContext<T>();

  // const error = get(errors, name);

  const classNames = [
    tw`border-gray-light`,
    tw`focus:bg-fuxs-orange-light`,
    tw`disabled:bg-gray-light2`,
    tw`h-10`,
    tw`rounded`,
    tw`border-2`,
    tw`focus:border-black`,
    tw`px-3`,
    tw`py-2`,
    tw`text-black`,
    tw`transition`,
    tw`focus:outline-none`,
    disabled || readOnly ? tw`disabled:cursor-not-allowed` : undefined,
    className?.split(' '),
  ];

  const setDisabled = readOnly ? true : disabled;

  return (
    <input
      type={type}
      className={classNames.filter(Boolean).join(' ')}
      {...register(name, { ...fieldOptions })}
      disabled={setDisabled}
      {...rest}
    />
  );
}
