import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { api } from '../../api';
import { useUpdateMessageState } from '../../MessageProvider';
import GroupForm from './components/GroupForm';

export function EditGroupPage() {
  const { group, subjects } = useLoaderData() as {
    group: CompetencyGroup;
    subjects: Subject[];
  };
  const navigate = useNavigate();
  const updateMessageState = useUpdateMessageState();

  function onCancel() {
    navigate('..');
  }

  const saveForm = async (group: CompetencyGroup) => {
    console.log('Saving', group);
    try {
      await api.updateCompentencyGroup(group);
      updateMessageState({
        text: 'Die Gruppe wurde erfolgreich gespeichert.',
        level: 'success',
      });
      navigate('..');
    } catch (error: unknown) {
      console.log(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Gruppe editieren</h1>
      <GroupForm group={group} subjects={subjects} onSubmit={saveForm} onCancel={onCancel} />
    </div>
  );
}
