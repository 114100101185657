import { JSX } from 'react/jsx-runtime';
import { NavLink, NavLinkProps } from 'react-router-dom';

export function TabNavLink(props: NavLinkProps) {
  const { children, className, ...rest } = props;

  return (
    <NavLink
      className={({ isActive }) => {
        return (
          [
            'inline-block',
            'size-full',
            'px-4',
            'py-1',
            'select-none',
            'cursor-pointer',
            'transition-all',
            'border border-black',
            isActive ? 'bg-fuxs-orange text-white' : 'hover:bg-fuxs-orange-light',
            'ring-offset-fuxs-orange-light focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-fuxs-orange-light focus-visible:ring-offset-2',
          ].join(' ') + (className || '')
        );
      }}
      {...rest}
    >
      {children}
    </NavLink>
  );
}

function TabNavItem({ link, children }: JSX.IntrinsicElements['li'] & { link: string }) {
  return (
    <li>
      <TabNavLink to={link}>{children}</TabNavLink>
    </li>
  );
}

export type TabNavPropsItem = {
  label: string;
  link: string;
};

type TabNavProps = {
  items: TabNavPropsItem[];
};

export function TabNav({ items, className }: JSX.IntrinsicElements['ul'] & TabNavProps) {
  return (
    <ul className={`flex gap-2 ${className}`}>
      {items.map((item, i) => (
        <TabNavItem key={i} link={item.link}>
          {item.label}
        </TabNavItem>
      ))}
    </ul>
  );
}
