import type { CompetencyGroup } from '@backend/model/competencies/types';
import type { Subject } from '@backend/model/subjects';
import { TaskType } from '@backend/model/tasks/types';
import type { ServiceResult } from '@backend/service';
import { createBrowserRouter, Navigate, Params } from 'react-router-dom';

import { api } from './api';
import { App } from './App';
import { CompetenciesListPage } from './curriculum/competencies/CompentenciesListPage';
import { EditCompetencyPage } from './curriculum/competencies/EditCompetencyPage';
import { NewCompetencyPage } from './curriculum/competencies/NewCompetencyPage';
import { CurriculumPage } from './curriculum/CurriculumPage';
import { EditGroupPage } from './curriculum/groups/EditGroupPage';
import { GroupsListPage } from './curriculum/groups/GroupsListPage';
import { NewGroupPage } from './curriculum/groups/NewGroupPage';
import { competenciesLoader, groupsLoader, studentsCompetenciesLoader, subjectsAndGroupsLoader } from './curriculum/loaders';
import { EditSubjectPage } from './curriculum/subjects/EditSubjectPage';
import { subjectLoader, subjectsLoader } from './curriculum/subjects/loaders';
import { NewSubjectPage } from './curriculum/subjects/NewSubjectPage';
import { SubjectPage } from './curriculum/subjects/SubjectPage';
import { SubjectsListPage } from './curriculum/subjects/SubjectsListPage';
import { EntitiesPage } from './entities/EntitiesPage';
import { EditMaterialPage } from './entities/materials/EditMaterialPage';
import { materialLoader, materialsLoader } from './entities/materials/loaders';
import { MaterialPage } from './entities/materials/MaterialPage';
import { MaterialsListPage } from './entities/materials/MaterialsListPage';
import { NewMaterialPage } from './entities/materials/NewMaterialPage';
import { EditTaskPage } from './entities/tasks/EditTaskPage';
import { taskLoader, tasksLoader } from './entities/tasks/loaders';
import { NewTaskPage } from './entities/tasks/NewTaskPage';
import { TaskPage } from './entities/tasks/TaskPage';
import { TasksListPage } from './entities/tasks/TasksListPage';
import { ErrorPage } from './ErrorPage';
import { Home } from './Home';
import { combinePropertyLoaders } from './lib/loaders';
import { StudentPlanPage } from './student/StudentPlanPage';
import { StudentsCompetenciesPage } from './students/competencies/StudentsCompetenciesPage';
import { detailPlanLoader, planLoader, plansLoader, studentLoader } from './students/loaders';
import { EditPlanPage } from './students/plans/EditPlanPage';
import { NewPlanPage } from './students/plans/NewPlanPage';
import { StudentsPlansPage } from './students/plans/StudentsPlansPage';
import { ViewPlanPage } from './students/plans/ViewPlanPage';
import { StudentsPage } from './students/StudentsPage';
import { StudentsReportsPage } from './students/StudentsReportsPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Home />,
      },

      // Student routes
      {
        path: 'plan',
        loader: async () => {
          const plan = await api.fetchMyActivePlan();
          // REVISIT: Do we want to redirect to the home page or show this error message?
          if (!plan) {
            throw new Response('Not Found', { status: 404 });
          }
          return plan;
        },
        element: <StudentPlanPage />,
      },

      // Teacher routes
      {
        path: 'students',
        loader: async () => {
          return await api.findStudents();
        },
        element: <StudentsPage />,
        children: [
          {
            path: '',
            element: <Navigate to="./plans" />,
          },
          {
            path: 'plans',
            children: [
              {
                path: '',
                loader: async () => {
                  return await api.findStudents();
                },
                element: <StudentsPlansPage />,
              },
              {
                path: ':studentId',
                loader: async ({ params, request }: { params: Params; request: Request }) => {
                  return {
                    student: await api.fetchUser(Number(params.studentId)),
                    plans: await plansLoader({ params, request }),
                  };
                },
                element: <StudentsPlansPage />,
              },
              {
                path: ':studentId/new',
                loader: combinePropertyLoaders(subjectsAndGroupsLoader, studentLoader),
                element: <NewPlanPage />,
              },
              {
                path: ':studentId/:id',
                loader: combinePropertyLoaders(subjectsAndGroupsLoader, detailPlanLoader),
                element: <ViewPlanPage />,
              },
              {
                path: ':studentId/:id/edit',
                loader: combinePropertyLoaders(subjectsAndGroupsLoader, planLoader),
                element: <EditPlanPage />,
              },
            ],
          },
          {
            path: 'reports',
            children: [
              {
                path: '',
                loader: async () => {
                  return await api.findStudents();
                },
                element: <StudentsReportsPage />,
              },
              {
                path: ':studentId',
                loader: async ({ params }: { params: Params }) => {
                  return {
                    student: await api.fetchUser(Number(params.studentId)),
                  };
                },
                element: <StudentsReportsPage />,
              },
            ],
          },
          {
            path: 'competencies',
            children: [
              {
                path: '',
                loader: async () => {
                  return await api.findStudents();
                },
                element: <StudentsCompetenciesPage />,
              },
              {
                path: ':studentId',
                loader: combinePropertyLoaders(
                  subjectsAndGroupsLoader,
                  studentsCompetenciesLoader,
                  async ({ params }: { params: Params }) => ({
                    student: await api.fetchUser(Number(params.studentId)),
                  })
                ),
                element: <StudentsCompetenciesPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'entities',
        element: <EntitiesPage />,
        children: [
          {
            path: '',
            element: <Navigate to="./tasks" />,
          },
          {
            path: 'tasks',
            children: [
              {
                path: '',
                loader: combinePropertyLoaders(subjectsAndGroupsLoader, tasksLoader(TaskType.Normal)),
                element: <TasksListPage type={TaskType.Normal} />,
              },
              {
                path: ':id',
                children: [
                  {
                    path: '',
                    loader: combinePropertyLoaders(subjectsAndGroupsLoader, taskLoader),
                    element: <TaskPage type={TaskType.Normal} />,
                  },
                  {
                    path: 'edit',
                    loader: combinePropertyLoaders(subjectsAndGroupsLoader, taskLoader),
                    element: <EditTaskPage type={TaskType.Normal} />,
                  },
                ],
              },
              {
                path: 'new',
                loader: subjectsAndGroupsLoader,
                element: <NewTaskPage type={TaskType.Normal} />,
              },
            ],
          },
          {
            path: 'projects',
            children: [
              {
                path: '',
                loader: combinePropertyLoaders(subjectsAndGroupsLoader, tasksLoader(TaskType.Project)),
                element: <TasksListPage type={TaskType.Project} />,
              },
              {
                path: ':id',
                children: [
                  {
                    path: '',
                    loader: combinePropertyLoaders(subjectsAndGroupsLoader, taskLoader),
                    element: <TaskPage type={TaskType.Project} />,
                  },
                  {
                    path: 'edit',
                    loader: combinePropertyLoaders(subjectsAndGroupsLoader, taskLoader),
                    element: <EditTaskPage type={TaskType.Project} />,
                  },
                ],
              },
              {
                path: 'new',
                loader: subjectsAndGroupsLoader,
                element: <NewTaskPage type={TaskType.Project} />,
              },
            ],
          },
          {
            path: 'materials',
            children: [
              {
                path: '',
                loader: materialsLoader,
                element: <MaterialsListPage />,
              },
              {
                path: ':id',
                children: [
                  {
                    path: '',
                    loader: materialLoader,
                    element: <MaterialPage />,
                  },
                  {
                    path: 'edit',
                    loader: materialLoader,
                    element: <EditMaterialPage />,
                  },
                ],
              },
              {
                path: 'new',
                element: <NewMaterialPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'curriculum',
        element: <CurriculumPage />,
        children: [
          {
            path: '',
            element: <Navigate to="./subjects" />,
          },
          {
            path: 'subjects',
            children: [
              {
                path: '',
                loader: subjectsLoader,
                element: <SubjectsListPage />,
              },
              {
                path: ':id',
                children: [
                  {
                    path: '',
                    loader: subjectLoader,
                    element: <SubjectPage />,
                  },
                  {
                    path: 'edit',
                    loader: subjectLoader,
                    element: <EditSubjectPage />,
                  },
                ],
              },
              {
                path: 'new',
                element: <NewSubjectPage />,
              },
            ],
          },
          {
            path: 'groups',
            children: [
              {
                path: '',
                loader: async ({
                  request,
                }: {
                  request: Request;
                }): Promise<{ groups: ServiceResult<CompetencyGroup[]>; subjects: Subject[] }> => ({
                  groups: await groupsLoader({ request }),
                  subjects: (await api.findSubjects({ orderBy: 'label' })).result,
                }),
                element: <GroupsListPage />,
              },
              {
                path: ':id',
                loader: async ({ params }: { params: Params }) => ({
                  group: await api.fetchCompetencyGroup(Number(params.id)),
                  subjects: (await api.findSubjects({ orderBy: 'label' })).result,
                }),
                element: <EditGroupPage />,
              },
              {
                path: 'new',
                loader: async () => ({
                  subjects: (await api.findSubjects({ orderBy: 'label' })).result,
                }),
                element: <NewGroupPage />,
              },
            ],
          },
          {
            path: 'competencies',
            children: [
              {
                path: '',
                loader: combinePropertyLoaders(subjectsAndGroupsLoader, competenciesLoader),
                element: <CompetenciesListPage />,
              },
              {
                path: ':id',
                loader: combinePropertyLoaders(subjectsAndGroupsLoader, async ({ params }) => ({
                  competency: await api.fetchCompetency(Number(params.id)),
                })),
                element: <EditCompetencyPage />,
              },
              {
                path: 'new',
                loader: subjectsAndGroupsLoader,
                element: <NewCompetencyPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
