import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router';

import type { Plan, PlanWithTasks, UserPlanTask } from '../../../../backend/src/model/plans/types';
import { type Task, TaskType } from '../../../../backend/src/model/tasks/types';
import type { User } from '../../../../backend/src/model/users/types';
import { api } from '../../api';
import type { SubjectsAndGroups } from '../../curriculum/loaders';
import { useUpdateMessageState } from '../../MessageProvider';
import { useStack } from '../../StackProvider';
import PlanForm from './components/PlanForm';
import { splitProjectsAndTasks } from './utils';

export function NewPlanPage() {
  const { student, ...subjectAndGroups } = useLoaderData() as SubjectsAndGroups & { student: User };

  const { studentId } = useParams();
  if (!studentId) {
    throw new Error('No studentId provided');
  }

  const navigate = useNavigate();
  function onCancel() {
    navigate(`../${studentId}`);
  }

  const updateMessageState = useUpdateMessageState();
  const saveForm = async (data: Task) => {
    const { userId, target, taskIds = [], projectIds = [] } = data;
    const payload = {
      userId,
      target,
      taskIds: [...taskIds, ...projectIds],
    };
    console.log('Saving', payload);

    try {
      await api.newPlan(payload);
      updateMessageState({
        text: 'Der neue Plan wurde erfolgreich gespeichert',
        level: 'success',
      });

      navigate(`../${studentId}`);
    } catch (error) {
      console.error(error);
      const message = error instanceof Error ? error.message : 'Unbekannter Fehler';
      updateMessageState({
        text: message,
        level: 'error',
      });
    }
  };

  const location = useLocation();
  const { stackPush } = useStack();
  const onNewTask = (type: TaskType, plan: Plan) => {
    const { pathname, search } = location;
    stackPush({
      label: `Neuer Arbeitsplan für ${student.displayName}`,
      pathname,
      search,
      type: 'unsaved',
      state: plan,
    });
    if (type === TaskType.Project) {
      navigate('/entities/projects/new');
      return;
    }
    navigate('/entities/tasks/new');
  };

  const formPlan: PlanWithTasks & { projects: UserPlanTask[] } = splitProjectsAndTasks(location.state);

  return (
    <div>
      <h1 className="mb-4 text-2xl font-bold">Neuer Arbeitsplan für {student.displayName}</h1>
      <PlanForm
        {...subjectAndGroups}
        plan={formPlan}
        studentId={Number(studentId)}
        onSubmit={saveForm}
        onCancel={onCancel}
        onNewtTask={onNewTask}
      />
    </div>
  );
}
